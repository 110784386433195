import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import modalert from "./modal.module.scss";

//  class component
class App extends Component {
  state = { showHide: true };
  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  render() {
    let { title, heading, content, subHead } = this.props;
    return (
      <>
        {/* <Button variant="primary" onClick={() => this.handleModalShowHide()}>
          Launch demo modal
        </Button> */}
        <Modal
          show={this.state.showHide}
          aria-labelledby="contained-modal-title-vcenter"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          centered={true}
        >
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {subHead} <span className="alert-text-strong">{heading}</span>{" "}
              {content}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={modalert.btnModal}
              data-dismiss="modal"
              type="button"
              onClick={() => this.handleModalShowHide()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
// default export
export default App;
