import React, { Component } from "react";
import axios from "axios";
import contactform from "./contactform.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import CommonButton from "../exioComponents/commonButton/CommonButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Inputbox from "../inputbox/inputbox";

const sendData = async (obj, url) => {
  let res = await axios({
    method: "post",
    url: url,
    headers: {
      "content-type": "application/json;charset=utf-8",
    },
    data: obj,
  });
  console.log(res.data);
  return res.data;
};

class Contactform extends Component {
  state = {
    validated: false,
    name: "",
    email: "",
    phoneNo: "",
    subject: "",
    message: "",
    termsCheck: false,
  };

  componentDidMount = async () => {
    let res = await axios.get(
      `https://admin.loadpool.co.za/api/v1/user/getProfile?token=${localStorage.getItem(
        "token"
      )}`
    );
    if (res.data.data) {
      let { name, email, phoneNo } = res.data.data;
      this.setState({
        name,
        email,
        phoneNo,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    const { name, email, subject, phoneNo, message } = this.state;
    let res = await sendData(
      { name, email, subject, phoneNo, message },
      "https://admin.loadpool.co.za/api/v1/contact/sendMessage"
    );
    if (res.statusCode === 201) {
      this.notify();
      this.setState({ registerSuccess: true });
    }
    this.setState({ validated: true });
  };

  notify = () =>
    toast("We got your request, we will call you as soon as possible!");

  setName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  setEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  setPhoneNo = (e) => {
    if (Number(e.target.value) || e.target.value === "") {
      this.setState({
        phoneNo: e.target.value,
      });
    }
  };
  setSubject = (e) => {
    this.setState({
      subject: e.target.value,
    });
  };

  setMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container
          className={contactform.container}
          style={{
            maxWidth: "1320px",
            border: "1px solid #ddd",
            padding: "50px",
			boxShadow: "0 0 20px 3px rgba(12, 12, 12, 0.05)",
			backgroundColor: "#fff",
			marginBottom: "30px",
			padding: "30px",
			transition: "all 0.5s",
			borderRadius: "4px",
			border: "0",
          }}
        >
          <Row>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Row className={contactform.rowkiClass}>
                <Form.Group as={Col} xl={6} className={contactform.formGroup}>
                  <Form.Control
                    className={contactform.formControl}
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    id="1"
                    required
                    value={this.state.name}
                    onChange={this.setName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your name
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={6} className={contactform.formGroup}>
                  <Form.Control
                    className={contactform.formControl}
                    type="email"
                    name="name"
                    placeholder="Your Email"
                    id="2"
                    required={true}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setEmail(e);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your email
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className={contactform.rowkiClass}>
                <Form.Group as={Col} xl={6} className={contactform.formGroup}>
                  <Form.Control
                    className={contactform.formControl}
                    type="text"
                    name="name"
                    placeholder="Your Contact Number"
                    id="3"
                    minLength={10}
                    maxLength={10}
                    required={true}
                    value={this.state.phoneNo}
                    onChange={this.setPhoneNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your number
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={6} className={contactform.formGroup}>
                  <Form.Control
                    className={contactform.formControl}
                    type="text"
                    name="name"
                    placeholder="Your Subject"
                    id="4"
                    required={true}
                    value={this.state.subject}
                    onChange={this.setSubject}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your subject
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className={contactform.rowkiClass}>
                <Form.Group className="contactform_formControl__SJzee" style={{
			marginBottom: "30px",
          }}>
                  <Form.Control
                    className="form-controls"
                    as="textarea"
                    rows={4}
                    name="name"
                    placeholder="Your Message"
                    id="5"
                    required={false}
                    value={this.state.message}
                    onChange={this.setMessage}
                  />
                  <Form.Control.Feedback type="invalid">
                    Write your message
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {/* <Row className={contactform.rowkiClass}>
                <Form.Group className={contactform.formGroup}>
                  <Form.Check
                    className={contactform.formCheck}
                    required
                    label="Agree to terms and conditions"
                    // TERMS AND POLICY KO ANCHOR TAG DALNA HAI
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </Form.Group>
              </Row> */}

              <Row className={contactform.loginBtn}>
                <Col lg={{ span: 6 }}>
                  <CommonButton type="submit" btnText="Send Message" />
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </>
    );
  }
}

export default Contactform;
