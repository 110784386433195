import React, { Component } from "react";
import singleTestimonial from "./singleTestimonial.module.scss";


class SingleTestimonial extends Component {
  render() {
    return (
      <div>
        <div className={`iskiclass ${singleTestimonial.iskiclass}`}>
          <div
            className={`single-test ${singleTestimonial.singleTestimonials}`}
          >
            <img src={this.props.photu} alt="Image" />
            <h3 className={singleTestimonial.singletestHeading}>
              {this.props.heading}
            </h3>
            <p className={singleTestimonial.singletestPara}>
              {this.props.paragraph}
            </p>
            <h4>{this.props.name}</h4>
            <span>{this.props.post}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleTestimonial;
