import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SingleServiceBox from "../singleServiceBox/singleServiceBox";
import ourservices from "./ourServices.module.scss";
import { ReactComponent as Truck } from "../../../resources/svg/fast-delivery.svg";
import { ReactComponent as Airplane } from "../../../resources/svg/airplane.svg";
import { ReactComponent as Ship } from "../../../resources/svg/ship.svg";

class OurServices extends Component {
  render() {
    return (
      <div className={ourservices.maindiv}>
        <Container className={ourservices.serviceTitle}>
          <Row className={ourservices.serviceRow}>
            <p className={ourservices.servicePara}>OUR SERVICES</p>
            <h1 className={ourservices.serviceHead}>
              We Are Trusted For Our Services
            </h1>
            <p className={ourservices.serviceContent}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit
              porro, aliquid reprehenderit iusto inventore sint beatae ipsum
              impedit in sit numquam illum distinctio sequi quisquam et hic
              tempore
            </p>
          </Row>
          <Row>
            <Col lg="4">
              <SingleServiceBox
                icon={<Truck style={{ height: "45px", width: "45px" }} />}
              />
            </Col>
            <Col lg="4">
              <SingleServiceBox
                icon={<Airplane style={{ height: "45px", width: "45px" }} />}
              />
            </Col>
            <Col lg="4">
              <SingleServiceBox
                icon={<Ship style={{ height: "45px", width: "45px" }} />}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OurServices;
