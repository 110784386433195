import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import contactinfo from "./contactinfoarea.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";
import contactus from "../../Pages/contactUSPage/contactuspage.module.scss";
import Contactform from "../contactform/contactform";

class Contactinfoarea extends Component {
  render() {
    return (
      <>
        <div className={contactinfo.contactsection}>
          <Container className={contactinfo.container}>
            <Row className={contactinfo.row}>
            <h1 className={contactus.title}>Drop Us A Message For Any Query</h1>
              <Col className={contactinfo.col} lg="6">
              <div className={contactus.headiv}>
            
          </div>
          <Contactform />
              </Col>



              <Col className={contactinfo.col} lg="6">
              <div className={contactinfo.singleContactInfo}>
                  <div
                    className={contactinfo.singleContactIcon}
                    ref={this.myref}
                  >
                    <FontAwesomeIcon
                      className={contactinfo.singleContactenvelope}
                      icon={faEnvelope}
                    ></FontAwesomeIcon>
                  </div>
                  <h3 className={contactinfo.singleContactHeading}>
                    Email Us:
                  </h3>
                  <a href="mailto:hello@ezio.com">hello@ezio.com</a>
                  <a href="mailto:info@ezio.com">info@ezio.com</a>
                </div>

                
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Contactinfoarea;
