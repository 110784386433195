import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Slider.module.scss";

class Slidernew extends Component {
  state = { value: 0 };

  render() {
    const { value, setValue } = this.props;

    return (
      <>
        <div className={styles.main}>
          <Row>
            <Col className={styles.col1}>
              <div className={styles.wrap}>
                <div
                  className={styles.pointer}
                  style={{
                    position: "relative",
                    left: `${value ? value : this.state.value}%`,
                    transform: `translateX(-${
                      value ? value : this.state.value
                    }%)`,
                    // transform: `translate(${value ? value : this.state.value}%, 0%)`,
                  }}
                >
                  {value ? value : this.state.value}
                </div>
                <input
                  type="range"
                  className={styles.range}
                  min="0"
                  max="100"
                  step="1"
                  value={value ? value : this.state.value}
                  onInput={
                    setValue
                      ? (e) => {
                          setValue(e.target.value);
                        }
                      : (e) => {
                          this.setState({ value: e.target.value });
                        }
                  }
                />
                <div className={styles.track}>
                  <div
                    style={{ width: `${value ? value : this.state.value}%` }}
                    className={styles.trackInner}
                  ></div>
                </div>
                <div
                  style={{
                    left: `${value ? value : this.state.value}%`,
                    transform: `translate(-${
                      value ? value : this.state.value
                    }%, -50%)`,
                  }}
                  className={styles.thumb}
                ></div>
              </div>
            </Col>
            <Col className={styles.valuebox} xs={4} md={3} sm={3} lg={2}>
              <input
                type="text"
                value={value ? value : this.state.value}
                className={styles.value}
                onChange={
                  setValue
                    ? (e) => {
                        setValue(e.target.value);
                      }
                    : (e) => {
                        this.setState({ value: e.target.value });
                      }
                }
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Slidernew;
