import React, { Component } from "react";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import aboutus from "./aboutUs.module.scss";
import Aboutus_2 from "../../UpdatedComponents/aboutUsCompartment/aboutus_2/aboutus_2";
import SingleCounter from "../../UpdatedComponents/aboutUsCompartment/singleCounter/singleCounter";
import Whychooseus from "../../UpdatedComponents/aboutUsCompartment/whychooseus/whychooseus";
import Ourtestimonial from "../../UpdatedComponents/aboutUsCompartment/ourTestimonials/ourtestimonial";
import Shipment from "../../UpdatedComponents/aboutUsCompartment/shipment2/shipment2";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";

class AboutUs extends Component {
  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={aboutus.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image------------------ */}
        {/* <AboutImg pageName="Our Concept" /> */}
        {/* <Container> */}
        {/* ------------------aboutus----------------- */}
        <Aboutus_2 />
        {/* -------------singlecounter----------------- */}
        {/* <SingleCounter /> */}
        {/* -------------whychooseus----------------- */}
        {/* <Whychooseus /> */}
        {/* -------------shipments--------------------- */}
        {/* <Shipment /> */}
        {/* -------------ourtestimonials-------------- */}
        {/* <Ourtestimonial /> */}

        {/* ------------footer---------------------- */}
        {/* <Footer /> */}
      </>
    );
  }
}

export default AboutUs;
