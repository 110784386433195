import React, { Component } from "react";
import resetpassword from "./resetpasspage.module.scss";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import Resetpassword from "../../UpdatedComponents/resetpassword/resetpassword";

class Resetpasswordpage extends Component {
  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={resetpassword.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image-------------------- */}
        {/*<AboutImg pageName="Recover Password!" />*/}
        {/* ---------------LogIn---------------- */}
        <div className={resetpassword.loginform}>
          <Resetpassword />
        </div>
        {/* <Footer /> */}
      </>
    );
  }
}

export default Resetpasswordpage;
