import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import labelboxsass from "./labelbox.module.scss";

class Labelbox extends Component {
  render() {
    return (
      <>
        <Form.Group
          as={Col}
          xl={12}
          className={labelboxsass.formGroup}
          controlId={this.props.id}
        >
          <Form.Label className={labelboxsass.iflabel}>
            {this.props.formlabel}
          </Form.Label>
          <Form.Control
            required={this.props.required}
            className={labelboxsass.formControl}
            type={this.props.type ? this.props.type : "text"}
            name="name"
            value={this.props.value}
            onChange={(e) => {
              this.props.setValue(e.target.value);
            }}
            maxLength={this.props.maxlength}
            minLength={this.props.minLength}
          />
          <Form.Control.Feedback type={this.props.feedback.type}>
            {this.props.feedback.content}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  }
}

export default Labelbox;
