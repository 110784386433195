import React, { Component } from "react";
import singleTestimonial from "./singleTestimonial.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import aboutus_2 from "../aboutus_2/aboutus_2.module.scss";
class SingleTestimonial extends Component {
  render() {
    return (
      <div>
        <div className={`iskiclass ${singleTestimonial.iskiclass}`}>
          <div style={{opacity:"1",boxShadow:"0 0 20px 3px rgba(0, 0, 0, 0.05)", margin:"30px 10px"}}
            className={`single-test ${singleTestimonial.singleTestimonials}`}
          >
            {/* <img src={this.props.photu} alt="Image" /> */}
            <h3 className={singleTestimonial.singletestHeading}>
              {this.props.heading}
            </h3>
            <p className={singleTestimonial.singletestPara}>
              {this.props.paragraph}
            </p>
            <h4>{this.props.name}</h4>
            <span>{this.props.post}</span>
            {(this.props.isulshow) && (
              <ul style={{listStyle:"none", textAlign:"left"}}>
			  <li>
				<FontAwesomeIcon
				  icon={faCheckCircle}
				  className={aboutus_2.icon}
				/> 24/7 Business Support
			  </li>
			  <li>
				<FontAwesomeIcon
				  icon={faCheckCircle}
				  className={aboutus_2.icon}
				/> Secure Transportation
			  </li>
			</ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SingleTestimonial;
