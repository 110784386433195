import React, { useEffect, useState } from "react";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
// import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import Navbarr from "../../UpdatedComponents/exioComponents/nav/Navbar";
import MyAccountStyles from "./MyAccount.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import { useLocation } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const MyAccount = () => {
  useEffect(() => {
    getData();
    getOrders();
    getCity();
  }, []);
  const location = useLocation();
  const { from } = location.state;

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNo: "",
  });

  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
  });

  const [dialogUserData, setdialogUserData] = useState({
    name: "",
    phoneNo: "",
  });

  const [newAddress, setnewAddress] = useState({
    type: "",
    address: { formatted_address: "" },
  });

  const [city, setCity] = useState([]);

  const [otherType, setotherType] = useState("");

  const length = 5;

  const [pagination, setPagination] = useState({
    first: 0,
    second: length,
  });

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [value, setValue] = React.useState(from);

  const handleChange = (event) => {
    setnewAddress({
      ...newAddress,
      type: event.target.value,
    });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const [orders, setOrders] = useState([]);
  const [address, setAddress] = useState([]);
  const notify = (text) =>
    toast.success(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const columns = [
    {
      name: "Sr No",
      key: 0,
    },
    {
      name: "From",
      key: 1,
    },
    {
      name: "To",
      key: 2,
    },
    {
      name: "Total Cost",
      key: 3,
    },
    {
      name: "Status",
      key: 4,
    },
    {
      name: "Placed On",
      key: 5,
    },
    {
      name: "Delivery Type",
      key: 6,
    },
    {
      name: "Insurance",
      key: 7,
    },
  ];

  const getData = async () => {
    const response = await axios.get(
      "https://admin.loadpool.co.za/api/v1/user/getProfile",
      {
        headers: {
          "content-type": "application/json;charset=utf-8",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    console.log(response);

    if (response.data.statusCode === 401) {
      window.location = "/login";
      return;
    }
    setUserData({
      name: response.data.data.name,
      phoneNo: response.data.data.phoneNo,
      email: response.data.data.email,
    });
    setdialogUserData({
      name: response.data.data.name,
      phoneNo: response.data.data.phoneNo,
    });
    setAddress(response.data.data.addresses);
  };

  const getOrders = async () => {
    const response = await axios.get(
      "https://admin.loadpool.co.za/api/v1/order/myOrders",
      {
        headers: {
          "content-type": "application/json;charset=utf-8",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    if (response.data.statusCode === 200) {
      setOrders(response.data.data);
    }
    // setUserData({
    //   name: response.data.data.name,
    //   phoneNo: response.data.data.phoneNo,
    //   email: response.data.data.email,
    // });
  };

  const setData = async (e) => {
    e.preventDefault();
    const response = await axios.put(
      "https://admin.loadpool.co.za/api/v1/user/editProfile",
      dialogUserData,
      {
        headers: {
          "content-type": "application/json;charset=utf-8",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    if (response.data.statusCode === 200) {
      setUserData({
        ...userData,
        name: dialogUserData.name,
        phoneNo: dialogUserData.phoneNo,
      });
      notify("Details Updated Successfully ");
      setOpen2(false);
    }
  };

  const addAddress = async (e) => {
    e.preventDefault();
    // https://admin.loadpool.co.za
    if (newAddress.type === "Other") {
      const response = await axios.put(
        "https://admin.loadpool.co.za/api/v1/user/addAddress",
        {
          address: newAddress.address,
          type: otherType,
        },
        {
          headers: {
            "content-type": "application/json;charset=utf-8",
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      if (response.data.statusCode === 200) {
        setAddress([
          ...address,
          {
            address: newAddress.address,
            type: otherType,
          },
        ]);
        notify("Address Added Successfully");
        setOpen(false);
        setnewAddress({
          type: "",
          address: "",
        });
      }
    } else {
      const response = await axios.put(
        "https://admin.loadpool.co.za/api/v1/user/addAddress",
        newAddress,
        {
          headers: {
            "content-type": "application/json;charset=utf-8",
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );

      console.log(response.data);
      if (response.data.statusCode === 200) {
        setAddress([...address, newAddress]);
        notify("Address Added Successfully");
        setOpen(false);
        setnewAddress({
          type: "",
          address: "",
        });
      }
    }
  };

  const deleteAddress = async (id) => {
    console.log("hi");
    const response = await axios.put(
      "https://admin.loadpool.co.za/api/v1/user/deleteAddress",
      {
        id,
      },
      {
        headers: {
          "content-type": "application/json;charset=utf-8",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    // console.log(response);

    if (response.data.statusCode === 200) {
      notify("Address Deleted Successfully");
      const temp = address.filter((item) => item._id !== id);
      setAddress(temp);
      // setOpen(false);
      // setnewAddress({
      //   type: "",
      //   address: "",
      // });
    }
  };

  const onClick = (e) => {
    // this.props.onChange(e.currentTarget.innerText);
    setnewAddress({
      ...newAddress,
      address: e.currentTarget.innerText,
    });
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    });
  };

  const getCity = async () => {
    const response = await axios.get(
      "https://admin.loadpool.co.za/api/v1/city/get"
    );

    // console.log(response.data.city);
    setCity(response.data.city);
  };

  return (
    <div>
      <a
        style={{ display: "none" }}
        href="https://icons8.com/icon/KHzt2hd6uNh5/delete"
      >
        Delete icon by Icons8
      </a>
      {/* Dialog  */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ margin: "auto" }}>
          <h3>Add Address</h3>
        </DialogTitle>
        <form onSubmit={addAddress}>
          <DialogContent>
            <DialogContentText>
              Please enter the address and type of address below
            </DialogContentText>
            <PlacesAutocomplete
              value={newAddress.address.formatted_address}
              onChange={async (address) => {
                setnewAddress({
                  ...newAddress,
                  address: {
                    formatted_address: address,
                  },
                });
              }}
              onSelect={async (value) => {
                const results = await geocodeByAddress(value);

                setnewAddress({
                  ...newAddress,
                  address: results[0],
                });

                // const latLng = await getLatLng(results[0]);
                console.log(results);
              }}
              // onKeyDown={onKeyDown}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextField
                    {...getInputProps({
                      required: true,
                      style: { marginBottom: "20px", marginTop: "20px" },
                      margin: "dense",
                      id: "name",
                      label: "Address",
                      type: "text",
                      fullWidth: true,
                      variant: "outlined",
                    })}
                  />
                  {/* loading && <div>Loading...</div> */}

                  <ul className={MyAccountStyles.suggestions} style={{ width: "362px" }}>
                    {suggestions.map((suggestion, index) => {
                      let className;

                      // Flag the active suggestion with a class
                      if (index === state.activeSuggestion) {
                        className = MyAccountStyles.suggestionActive;
                      }
                      let element;

                      suggestion.terms.forEach((ele) => {
                        city.forEach((c) => {
                          if (true || c.toLowerCase() == ele.value.toLowerCase()) {
                            
                            element = (
                              <li
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  key: index,
                                  onClick: onClick,
                                })}
                              >
                                {suggestion.description}
                              </li>
                            );
                          }
                        });
                      });
                      return element;
                    })}
                  </ul>
                </div>
              )}
            </PlacesAutocomplete>
            <InputLabel
              style={{ marginBottom: "20px" }}
              id="demo-simple-select-label"
            >
              Type
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newAddress.type}
              label=" "
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value={"Home"}>Home</MenuItem>
              <MenuItem value={"Work"}>Work</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
            {newAddress.type === "Other" && (
              <TextField
                required
                style={{ marginBottom: "20px", marginTop: "20px" }}
                margin="dense"
                id="name"
                label="Please Specify type"
                type="text"
                fullWidth
                variant="outlined"
                value={otherType}
                onChange={(e) => {
                  setotherType(e.target.value);
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog Profile  */}
      <Dialog open={open2} onClose={handleClose2}>
        <DialogTitle style={{ margin: "auto" }}>
          <h3>Edit Profile</h3>
        </DialogTitle>
        <form onSubmit={setData}>
          <DialogContent>
            <DialogContentText>
              Please enter the details below
            </DialogContentText>
            <TextField
              required
              style={{ marginBottom: "20px", marginTop: "20px" }}
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={dialogUserData.name}
              onChange={(e) => {
                setdialogUserData({
                  ...dialogUserData,
                  name: e.target.value,
                });
              }}
            />
            {/* <InputLabel id="demo-simple-select-label">Email</InputLabel> */}
            <TextField
              required
              style={{ marginBottom: "20px", marginTop: "20px" }}
              margin="dense"
              id="name"
              label="Phone Number"
              type="number"
              fullWidth
              variant="outlined"
              value={dialogUserData.phoneNo}
              onChange={(e) => {
                setdialogUserData({
                  ...dialogUserData,
                  phoneNo: parseInt(e.target.value),
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      <ToastContainer />
      <header className={MyAccountStyles.realHeader}>
        {/* <MainHeader /> */}
        <Navbarr />
      </header>
      {/*<AboutImg pageName="My Details" /> */}
      <div className={MyAccountStyles.container}>
        <Tabs value={value} onChange={handleTabChange} centered>
          <Tab label="My Details" />
          <Tab label="My Orders" />
        </Tabs>
        {value == 0 ? (
          <div
            style={{ marginBottom: "30px" }}
            className={MyAccountStyles.formContainer}
          >
            <div className={MyAccountStyles.formContainerInner}>
              <div className={MyAccountStyles.detailsContainer}>
                <div className={MyAccountStyles.detailsContainerInner}>
                  <h5 style={{ minWidth: "100px" }}>Name : </h5>{" "}
                  <span className={MyAccountStyles.detail}>
                    {userData.name}
                  </span>
                </div>
                <div
                  style={{}}
                  className={MyAccountStyles.detailsContainerInner}
                >
                  <h5 style={{ minWidth: "100px" }}>Email : </h5>{" "}
                  <span className={MyAccountStyles.detail}>
                    {userData.email}
                  </span>
                </div>
                <div className={MyAccountStyles.detailsContainerInner}>
                  <h5 style={{ minWidth: "100px" }}>Phone No : </h5>{" "}
                  <span className={MyAccountStyles.detail}>
                    {userData.phoneNo}
                  </span>
                </div>
              </div>

              <div className={MyAccountStyles.buttonContainer}>
                <button
                  onClick={handleClickOpen2}
                  className={MyAccountStyles.addButton}
                >
                  Edit Profile
                </button>
                <button
                  onClick={handleClickOpen}
                  className={MyAccountStyles.addButton}
                >
                  Add Address
                </button>
              </div>
            </div>

            {/* Address  */}
            {/* <button
              onClick={handleClickOpen}
              className={MyAccountStyles.addButton}
            >
              Add Address
            </button> */}
            <TableContainer
              className={MyAccountStyles.tableWidth}
              // style={{ width: "50%" }}
              component={Paper}
            >
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow className={MyAccountStyles.header}>
                    <TableCell style={{ color: "white" }}>Sr No.</TableCell>
                    <TableCell style={{ color: "white" }}>Address</TableCell>
                    <TableCell style={{ color: "white" }}>Type</TableCell>

                    <TableCell style={{ color: "white" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {address.map((item, index) => {
                    return (
                      // index < 4 &&
                      <TableRow
                        // key={order._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          {item.address.formatted_address}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.type}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            deleteAddress(item._id);
                          }}
                          align="left"
                        >
                          <img
                            style={{ width: "27px" }}
                            src="https://img.icons8.com/external-smashingstocks-circular-smashing-stocks/65/000000/external-delete-webmobile-applications-smashingstocks-circular-smashing-stocks.png"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <TableContainer
            style={{ margin: "30px 0px 0px 0px" }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className={MyAccountStyles.header}>
                  {columns.map((column) => (
                    <TableCell key={column.key} style={{ color: "white" }}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => {
                  return (
                    index >= pagination.first &&
                    index < pagination.second && (
                      <TableRow
                        key={order._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {order.from}
                        </TableCell>
                        <TableCell align="left">{order.to}</TableCell>
                        <TableCell align="left">{`R ${order.totalCost}`}</TableCell>
                        <TableCell align="left">{order.status}</TableCell>
                        <TableCell align="left">
                          {new Date(order.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">{order.deliveryType}</TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              width: "90%",
                              textAlign: "center",
                              color: "white",
                              padding: "10px",
                              borderRadius: "50px",
                              backgroundColor: order.insurance
                                ? "#5ac55a"
                                : "#ff4c4c",
                            }}
                          >
                            {order.insurance ? "Yes" : "No"}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {value == 0 ? (
          <div></div>
        ) : (
          <div className={MyAccountStyles.paginationContainer}>
            <button
              style={{
                cursor: `${pagination.first === 0 ? "no-drop" : "pointer"}`,
                display: pagination.first === 0 ? "none" : "flex",
              }}
              disabled={pagination.first === 0}
              className={MyAccountStyles.paginationButtons}
              onClick={() => {
                setPagination({
                  first: pagination.first - length,
                  second: pagination.second - length,
                });
              }}
            >
              Prev
            </button>
            <button
              className={MyAccountStyles.paginationButtons}
              style={{
                cursor: `${
                  orders.length - pagination.second <= 0 ? "no-drop" : "pointer"
                }`,
                display:
                  orders.length - pagination.second <= 0 ? "none" : "flex",
              }}
              disabled={orders.length - pagination.second <= 0}
              onClick={() => {
                setPagination({
                  first: pagination.first + length,
                  second: pagination.second + length,
                });
              }}
            >
              Next
            </button>
          </div>
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MyAccount;
