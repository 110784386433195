import React, { Component } from "react";
import Footerlist from "./FooterList.module.scss";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export class FooterList extends Component {
  fL = () => {
    if (this.props.flist) {
      return this.props.flist.map((e, i, arr) => {
        return (
          <li key={i} className={Footerlist.flistItem}>
            <Link
              to={e.to}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {e.title}
            </Link>
          </li>
        );
      });
    }
  };
  render() {
    return (
      <div style={{ marginBottom: "30px" }}>
        <h3 className={Footerlist.flistTitle}>{this.props.flistTitle}</h3>
        <ul className={Footerlist.listBox}>{this.fL()}</ul>
      </div>
    );
  }
}

export default FooterList;
