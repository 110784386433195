import React, { Component } from "react";
//png Import
import logo from "../../../resources/img/LOADPOOL-LOGO-removebg-preview.png";
//Bootstrap Import;
import { Container, Row, Col } from "react-bootstrap";
//component import
import FooterList from "../footerlist/FooterList";
//icon import
import "boxicons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {}
import { ReactComponent as Phone } from "../../../resources/phone-call.svg";

//scss import
import fstyle from "./Footer.module.scss";

export class Footer extends Component {
  render() {
    return (
      <div className={fstyle.footContainer}>
        <Container className={fstyle.container}>
          <Row className={fstyle.row}>
            <Col lg="3" md="6" className={fstyle.fCol}>
              <div style={{ marginBottom: "30px" }}>
                <div className={fstyle.akhar}>
                  <img src={logo} alt="logo" />
                </div>
                <div>
                  We are a national transporter operating on set routes thereby
                  frequenting all major cities throughout South Africa at least
                  2 -3 times per week.
                </div>
                <div className={fstyle.handles}>
                  <span className={fstyle.handlelogo}>
                    <box-icon
                      name="facebook"
                      type="logo"
                      color="#fff"
                      size="xs"
                    />
                  </span>
                  <span className={fstyle.handlelogo}>
                    <box-icon
                      type="logo"
                      name="instagram"
                      color="#fff"
                      size="xs"
                    />
                  </span>
                  <span className={fstyle.handlelogo}>
                    <box-icon
                      type="logo"
                      name="linkedin"
                      color="#fff"
                      size="xs"
                    />
                  </span>
                  <span className={fstyle.handlelogo}>
                    <box-icon
                      name="twitter"
                      type="logo"
                      color="#fff"
                      size="xs"
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" className={fstyle.sCol}>
              <FooterList
                flistTitle="Services"
                flist={[
                  { title: "Home", to: "/" },
                  { title: "Book Now", to: "/booknow" },
                  { title: "About us", to: "/aboutus" },
                  { title: "Contact", to: "/contactus" },
                ]}
              />
            </Col>
            <Col lg="3" md="6" className={fstyle.tCol}>
              <FooterList
                flistTitle="User"
                flist={[
                  { title: "Login", to: "/login" },
                  { title: "Signup", to: "/signup" },
                  { title: "My Account", to: "/myaccount" },
                  { title: "Reset Password", to: "/resetpassword" },
                ]}
              />
            </Col>
            <Col lg="3" md="6" className={fstyle.foCol}>
              <div style={{ marginBottom: "30px" }}>
                <h4>Address</h4>
                <ul className={fstyle.footerAdd}>
                  <li>
                    <div>
                      <box-icon color="#0b8dd4" name="location-plus" />
                    </div>
                    <div className={fstyle.map}>
                      9170 Millbrook Rd, Newark, IL 60541
                    </div>
                  </li>
                  <li>
                    <div>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "#0b8dd4" }}
                      />
                    </div>
                    <div className={fstyle.msg}>
                      <div>hello@example.com</div>
                      <div>info@example.com</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <box-icon
                        color="#0b8dd4"
                        name="phone-call"
                        type="solid"
                      />
                    </div>
                    <div className={fstyle.phoneUtha}>
                      +1 (123) 456 7890
                      <div />
                      <div>+1 (514) 312-6678</div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        {/* <Row className={fstyle.irow}>
          <p>
            Copyright &copy;2021 Ezio. Designed By <span>HiBootstrap</span>
          </p>
        </Row> */}
      </div>
    );
  }
}
export default Footer;
