import React, { Component } from "react";
import contactus from "./contactuspage.module.scss";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import Contactinfoarea from "../../UpdatedComponents/contactinfoarea/contactinfoarea";
import Contactform from "../../UpdatedComponents/contactform/contactform";

class Contactuspage extends Component {
  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={contactus.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image------------------ */}
        {/* <AboutImg pageName="Contact Us" /> */}
        {/* ---------------ContactInfo---------------- */}
        <Contactinfoarea />
        {/* <section className={contactus.section}>
          <div className={contactus.headiv}>
            <h1 className={contactus.title}>Drop Us A Message For Any Query</h1>
          </div>
          <Contactform />
        </section> */}
        {/*--------------------------- MAINMAINMAINMAINMAINmapdalnahai ---------------------------------*/}
        {/* <Footer /> */}
      </>
    );
  }
}

export default Contactuspage;
