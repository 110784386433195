import React, { Component } from "react";
//scss import '
import dataStyle from "./DataForm.module.scss";
//botstrap import
import { Col, Row } from "react-bootstrap";
//icons
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//component import
import Tnt from "../T&T/Tnt";
import RAQ from "../RAQ/RAQ";

//jquery import
import $ from "jquery";
window.jQuery = window.$ = $;
require("jquery-nice-select");

export class DataForm extends Component {
  render() {
    return (
      <>
        <div className={dataStyle.container}>
            <Row className={dataStyle.fRow}>
              <Col className={dataStyle.tntOption}>
                <FontAwesomeIcon icon={faMapMarkedAlt} /> Request a Quote
              </Col>
            </Row>
            <Row className={dataStyle.secRow}>
              <RAQ />
            </Row>
        </div>
      </>
    );
  }
}

export default DataForm;
