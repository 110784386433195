import React, { Component } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import md5 from "md5";

// components
import Booknownav from "../../UpdatedComponents/components/booknownav/booknownav";
import Mainbox from "../../UpdatedComponents/components/mainbox/Mainbox";
import bookingform from "./bookingformpage.module.scss";
import Dropdown from "../../UpdatedComponents/components/dropdown/Dropdown";
import BookingFormSelect from "../../UpdatedComponents/bookingformSelect/bookingformSelect";
import Slidernew from "../../UpdatedComponents/components/slider/Slider";
import Inputform from "../../UpdatedComponents/components/inputform/inputform";
import Checkbox from "../../UpdatedComponents/components/checkbox/Checkbox";
import Ordersummary from "../../UpdatedComponents/components/ordersummary/ordersummary";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Ty from "../../UpdatedComponents/exioComponents/ty/Ty";
import Inputbox from "../../UpdatedComponents/components/inputbox/inputbox";
// img
import image1 from "../../resources/img/img-1.png";
import image2 from "../../resources/img/img-2.png";
import image3 from "../../resources/img/img-3.png";
import image4 from "../../resources/img/img-4.png";

//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router";

window.jQuery = window.$ = $;
require("jquery-nice-select");

class PaymentSuccess extends Component {

  state = {
    isLoggedIn: true,
    thankyou: false,
    isValidated: false,
    orderSuccess: false,
  };

  // render

  render() {
      
    //---------
    return (
      <>
          <Ty isloggedin={this.state.isLoggedIn} />
      </>
    );
  }
}

export default PaymentSuccess;
