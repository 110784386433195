import React, { Component } from "react";
import SubmitButton from "../button/submitbutton";
import Checkbox from "../checkbox/Checkbox";
import Validationbox from "../validationbox/validationbox";
import styles from "./ordersummary.module.scss";
import $ from "jquery";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Cross } from "../../../resources/svg/delete-button.svg";

class Ordersummary extends Component {
  state = { width: window.innerWidth };
  myRef = React.createRef();

  listMaker = () => {
    let {
      data,
      deliveryType,
      valueOfGoods,
      isInsured,
      pickupType,
      dropOffType,
    } = this.props;
    if (data) {
      let tempList = data.map((ele, i) => {
        if (ele) {
          return (
            <li
              id={styles.optionGroup1Sum}
              style={{ fontFamily: "'Karla', sans-serif" }}
              key={i}
              className="selproducts"
              title={ele.title}
            >
              <Cross
                onClick={() => this.props.svgHandler(i)}
                style={{
                  height: "14px",
                  width: "14px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              />
              {ele.number > 1
                ? `${ele.size} x ${ele.number}`
                : `${ele.size} - R${ele.price}`}
              <div style={{ float: "right", marginRight: "10px" }}>
                R{`${Number(ele.number) * Number(ele.price)}`}
              </div>
            </li>
          );
        }
      });

      if (deliveryType) {
        tempList.push(
          <li
            id={styles.optionGroup1Sum}
            style={{ fontFamily: "'Karla', sans-serif" }}
            key={"ivbdvdno"}
          >
            Express Delivery Charges
            <div style={{ float: "right", marginRight: "10px" }}>R500</div>
          </li>
        );
      }
      if (isInsured) {
        if (valueOfGoods) {
          tempList.push(
            <li
              id={styles.optionGroup1Sum}
              style={{ fontFamily: "'Karla', sans-serif" }}
              key={"ekjvbsuoivnos"}
            >
              Insurance
              <div style={{ float: "right", marginRight: "10px" }}>
                R{`${(valueOfGoods * 3) / 100}`}
              </div>
            </li>
          );
        }
      }
      if (pickupType) {
        tempList.push(
          <li
            id={styles.optionGroup1Sum}
            style={{ fontFamily: "'Karla', sans-serif" }}
            key={"ibsiuvbvsuoivnos"}
          >
            Pickup Charges
            <div style={{ float: "right", marginRight: "10px" }}>R500</div>
          </li>
        );
      }
      if (dropOffType) {
        tempList.push(
          <li
            id={styles.optionGroup1Sum}
            style={{ fontFamily: "'Karla', sans-serif" }}
            key={"ibsiuvbvsuoivnosbvb"}
          >
            DropOff Charges
            <div style={{ float: "right", marginRight: "10px" }}>R500</div>
          </li>
        );
      }
      return tempList;
    } else {
      return () => {};
    }
  };

  checkSet = () => {
    let { check } = this.props;
    let { checkTotal } = check;
    if (!checkTotal) checkTotal = {};
    checkTotal.style = { display: "none" };

    return null;
  };

  componentDidMount = () => {
    var $window = $(window);
    var $sidebar = $(this.myRef.current);
    var $sidebarOffset = $sidebar.offset();
    $window.scroll(() => {
      if ($window.scrollTop() > $sidebarOffset.top && window.innerWidth > 993) {
        $sidebar.addClass(styles.fixed);
      } else {
        $sidebar.removeClass(styles.fixed);
      }
    });
  };

  render() {
    let { check, tnc, illegal, setIllegal, setTnc, resetBtn, total } =
      this.props;
    let { checkTotal, checkTnc, checkIllegal } = check;
    if (!checkTotal) checkTotal = {};

    // this.setState({ total: temp });
    return (
      <>
        {/* <Container  className={styles.sidebar}> */}
        <Container
          ref={this.myRef}
          id={styles.orderContainer}
          className={styles.theiaStickySidebar}
        >
          <Row>
            <Col md={12}>
              <h3>Order Summary</h3>
              <ul id={styles.orderSumList}>{this.listMaker()}</ul>
              <Row className={styles.totalContainer}>
                <Col md={6} xs={6}>
                  <input
                    type="text"
                    id={styles.totalTitle}
                    className={styles.summaryInput}
                    name="totallabel"
                    value="Total"
                    disabled
                  />
                </Col>
                <Col md={6} xs={6}>
                  <input
                    type="text"
                    id={styles.total}
                    className={styles.summaryInput}
                    name="total"
                    value={`R${total}`}
                    data-parsley-errors-container="#totalError"
                    data-parsley-empty-order=""
                    disabled
                  />
                </Col>
              </Row>
              <Validationbox
                text={
                  checkTotal
                    ? checkTotal.text
                      ? checkTotal.text
                      : this.checkSet()
                    : this.checkSet()
                }
                style={
                  checkTotal ? (checkTotal.style ? checkTotal.style : {}) : {}
                }
              />
              <Validationbox
                text={
                  checkTotal
                    ? checkTotal.textErr
                      ? checkTotal.textErr
                      : this.checkSet()
                    : this.checkSet()
                }
                style={
                  checkTotal.textErr ? ({ display: "block" } : { display: "none" }) : { display: "none" }
                }
              />
            </Col>
          </Row>
          <Row className={styles.oscheckbox}>
            <Checkbox
              padding="0"
              check={{ text: checkTnc.text }}
              border="none"
              height="0px"
              inputId="TnCCheckbox"
              index={"0"}
              value={tnc}
              setValue={setTnc}
              label={
                <>
                  Accept
                  <a
                    href="pdf/terms.pdf"
                    className={styles.termsLink}
                    target="_blank"
                  >
                    Terms
                  </a>
                  .
                </>
              }
            />
          </Row>
          <Row className={styles.oscheckbox}>
            <Checkbox
              padding="0"
              style={{ margin: "0px 0px" }}
              check={{ text: checkIllegal.text }}
              border="none"
              textWidth="85%"
              height="0px"
              align="top"
              inputId="IllegalCheckbox"
              index={"0"}
              value={illegal}
              setValue={setIllegal}
              label={
                <>
                  Your goods must be securely boxed and sealed. This confirms
                  that your goods transported aren't of an illegal or harmful
                  nature.
                </>
              }
            />
          </Row>


          <Row style={{ paddingTop: "0" }}>
            <SubmitButton
              leftborder="1px solid #fff"
              background="#0b8dd4"
              color="#fff"
              tick="fa fa-check"
              value="SUBMIT"
              colorprop="#fff"
            />
            <SubmitButton
              leftborder="1px solid #0b8dd4"
              background="#fff"
              onClick={resetBtn}
              color="#0b8dd4"
              tick="fa fa-times"
              value="RESET"
              colorprop="#0b8dd4"
            />
          </Row>
          <Row className={styles.footer}>
            <Col lg={12} center="true">
              <small> &copy; 2022 Loadpool. </small>
            </Col>
          </Row>
        </Container>
        {/* </Container> */}
      </>
    );
  }
}

export default Ordersummary;
