import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./ourtestimonial.module.scss";
import SingleTestimonial from "../singleTestimonial/singleTestimonial";
// --------------carousel---------------------
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//photos
import profileimg from "../../../resources/img/8.png";
import one from "../../../resources/img/7.png";
import two from "../../../resources/img/9.png";
import three from "../../../resources/img/10.png";

class Ourtestimonial extends Component {
  state = { owl: 3 };

  componentDidMount = () => {
    if (window.innerWidth < 960) {
      this.setState({ owl: 1 });
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 960) {
        this.setState({ owl: 1 });
      }
    });
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", () => {
      if (window.innerWidth < 960) {
        this.setState({ owl: 1 });
      }
    });
  };
  render() {
    return (
      <>
        <div className={styles.container} style={{ overflowX: "hidden" }}>
          <Row className={styles.row}>
            {/* <p className={styles.testimonialPara}>USE US</p> */}
            <h1 className={styles.testimonialHead}>
              Who should use us and why?
            </h1>
          </Row>
          <Row className={styles.testimonialRow2}>
            <OwlCarousel
              items={this.state.owl}
              autoplayTimeout={4000}
              smartSpeed={1000}
              className={`owl-theme single-test-main`}
              fluidSpeed
              autoplay
              autoplayHoverPause
              responsiveClass
              loop
              center
              margin={0}
              dots={false}
              nav={true}
              navText={['<img src="arrow-right.png" style="width: 48px;height: 48px;"/>','<img src="arrow-left.png" style="width: 48px;height: 48px;"/>']}
            >
              <Col>
                <SingleTestimonial
                  heading="Businesses Of All Sizes"
                  paragraph="We’ve all got that loved one or acquaintance living in another province whom we’d like to send new or used goods to at an affordable price. Why not Loadpool it? Our Unparalleled Rates = Cost Savings For You Hassle Free Experience – We’ll even collect from you Flexibility and Choice"
                  photu={profileimg}
                />
              </Col>
              <Col>
                <SingleTestimonial
                  heading="Ordinary Citizens"
                  paragraph="We’ve all got that loved one or acquaintance living in another province whom we’d like to send new or used goods to at an affordable price. Why not Loadpool it? Our Unparalleled Rates = Cost Savings For You Hassle Free Experience – We’ll even collect from you Flexibility and Choice"
                  photu={one}
                />
              </Col>
              <Col>
                <SingleTestimonial
                  heading="National Retail Chains"
                  paragraph="Perfect for small volume Inter Branch Transfers so why not Loadpool it? Our Unparalleled Rates = Cost Savings For Your Business Hassle Free Experience – We’ll even collect from you Flexibility and Choice"
                  photu={two}
                />
              </Col>
              <Col>
                <SingleTestimonial
                  heading="Courier & Transport Logistics Companies"
                  paragraph="There’s always that one load that’s not big enough to make a cross province trip financially viable so why not Loadpool it?Our Unparalleled Rates = Cost Savings For You Hassle Free Experience – We’ll even collect from you Flexibility and Choice"
                  photu={three}
                />
              </Col>
            </OwlCarousel>
          </Row>
        </div>
      </>
    );
  }
}

export default Ourtestimonial;
