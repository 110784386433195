import React, { Component } from "react";
import video from "../../../resources/video/loadpool.mp4";

//bootstrap import
import { Row, Col } from "react-bootstrap";

//scss import
import bg2 from "./BG2.module.scss";

//icons import
import "boxicons";

//img import
import image from "../../../resources/LoadpoolImages/homepage-banner.jpg";
import bookonline from "../../../resources/img/bookonline.png";

//component import
import mainVideo from "../video/Video";
import CommonButton from "../../exioComponents/commonButton/CommonButton";

export class BG2 extends Component {
  render() {
    return (
      <>
        <Row style={{ padding: "110px !important" }} className={bg2.container}>
          <Col
            style={{ padding: "0 !important" }}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className={bg2.imgBanner}
          >
            <p className={bg2.firstLine}>Tired of being asked</p>
            <p className={bg2.secLine}>Length, breadth, height & weight? </p>
            <p className={bg2.thirdLine}>NEVER AGAIN!</p>
            <div className={bg2.bookimage}>
              <a href="/booknow"><img
                className={bg2.bookonline}
                src={bookonline}
                alt="bookonline"
              /></a>
            </div>
            <p className={bg2.fourthLine}>LOADPOOL IT </p>
            <p className={bg2.fifthLine}>
              Send almost anything between JHB & DBN from as little as R500
            </p>
            <div className={bg2.btns}>
              <div className={bg2.btn1}>
                <CommonButton to="/booknow" btnText="Book Now" />
              </div>
              <div className={bg2.btn2}>
                <CommonButton to="/ourconcept" btnText="Our Concept" />
              </div>
            </div>
          </Col>
          <Col
            style={{ padding: "0 !important" }}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            className={bg2.videoBanner}
          >
            <video src={video} width="100%" height="100%" controls autoPlay />
          </Col>
        </Row>
      </>
    );
  }
}

export default BG2;
