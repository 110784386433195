import React, { Component } from "react";
import loginform from "./loginform.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import Labelbox from "../exioComponents/labelbox/labelbox";
import CommonButton from "../exioComponents/commonButton/CommonButton";
import { Link, Navigate } from "react-router-dom";
import authHelper from "../../heplers/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sendData = async (obj, url) => {
  const { email, password } = obj;
  let res = await authHelper.login(email, password, url);

  return res;
};

class Loginform extends Component {
  state = {
    validated: false,
    emailPhone: "",
    password: "",
    rememberCheck: false,
    loginSuccess: false,
    error: "",
  };

  setPassword = (value) => {
    this.setState({
      password: value,
    });
  };

  setEmailPhone = (value) => {
    this.setState({
      emailPhone: value,
    });
  };

  notify = () => {
    toast.error(this.state.error, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { emailPhone, password } = this.state;
      let res = await sendData(
        { email: emailPhone, password },
        "https://admin.loadpool.co.za/api/v1/user/login"
      );
      console.log(res);
      if (res.statusCode === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.name);
        this.setState({ loginSuccess: true });
      } else {
        this.setState({ error: "Incorrect Name or Password !" }, () => {
          this.notify();
        });
      }
    }

    this.setState({ validated: true });
  };
  render() {
    return (
      <>
        <ToastContainer className={loginform.settingToast} />
        <Container
          style={{
            maxWidth: "650px",
            padding: "20px",
            boxShadow: "0 0 20px 3px rgba(24, 23, 23, 0.05)",
            marginBottom: "100px"
          }}
        >
          <Row>
            <Col className={loginform.accountTitle}>
            {(localStorage.getItem("isRegister") && localStorage.getItem("isRegister") == "true") && (
              <h4>THANK YOU FOR YOUR REGISTRATION. ONE OF OUR AGENTS WILL CONNECT WITH YOU ONCE YOUR REGISTRATION IS APPROVED</h4>
            )}
            <h2>Log in</h2>
            </Col>
          </Row>
          <Row>
          	<Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Row className={loginform.formGroup}>
                <Labelbox
                  formlabel="Email or Phone"
                  feedback={{ type: "invalid", content: "Invalid" }}
                  id="1"
                  required
                  value={this.state.emailPhone}
                  setValue={this.setEmailPhone}
                />
                <Labelbox
                  formlabel="Password"
                  feedback={{ type: "invalid", content: "Invalid" }}
                  id="2"
                  type="password"
                  required={true}
                  value={this.state.password}
                  setValue={this.setPassword}
                  minLength={4}
                />
              </Row>
              <Row className={loginform.loginAction}>
                <Form.Group as={Col} controlId="remember">
                  <Form.Check
                    onClick={() => {
                      this.setState({
                        rememberCheck: !this.state.rememberCheck,
                      });
                    }}
                    className={loginform.checkbox}
                    label="Remember me!"
                  />
                </Form.Group>
                <Col lg={{ span: 4, offset: 3 }}>
                  <Link to="/resetpassword">Forgot your password?</Link>
                </Col>
              </Row>
              <Row className={loginform.loginBtn}>
                <Col lg={{ span: 4 }}>
                  <CommonButton type="submit" btnText="Log in now" />
                </Col>
              </Row>
              <Row className={loginform.lastrow}>
                <p>
                  Don't have an account? <Link to="/signup">Register Now!</Link>
                </p>
              </Row>
            </Form>
          </Row>
          {localStorage.setItem("isRegister", false)}
          {this.state.loginSuccess && <Navigate to="/booknow" />}
        </Container>
      </>
    );
  }
}

export default Loginform;
