import React, { Component } from "react";
import radiobtn from "./radiobtn.module.scss";

class Radiobtn extends Component {
  render() {
    return (
      <>
        <label className={radiobtn.container}>
          {this.props.labelName}
          <input type="radio" checked="checked" name="radio" />
          <span className={radiobtn.checkmark}></span>
        </label>
      </>
    );
  }
}

export default Radiobtn;
