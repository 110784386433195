import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../exioComponents/commonButton/CommonButton";

import resetpassword from "./resetpassword.module.scss";

class Resetpassword extends Component {
  state = { email: "" };

  setEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  render() {
    return (
      <Container
        style={{
          maxWidth: "700px",
          height: "430px",
          padding: "20px",
          boxShadow: "0 0 20px 3px rgba(24, 23, 23, 0.05)",
           marginBottom: "100px"
        }}
      >
        <Row>
          <Col>
            <h3 className={resetpassword.passhead}>Reset Password!</h3>
          </Col>
        </Row>
        <Row>
          <p className={resetpassword.para}>
            Enter the email of your account to reset the password. Then you will
            receive a link to email to reset the password. If you have any issue
            about reset password
            <Link className={resetpassword.anchor1} to="/contactus">
              contact us.
            </Link>
          </p>
        </Row>
        <Row>
          <form method="post">
            {/* <div class="row"> */}
            <Row className={resetpassword.inputKiRow}>
              <Col className={resetpassword.formGroup}>
                <input
                  className={resetpassword.formControl}
                  type="email"
                  name="name"
                  value={this.state.email}
                  placeholder="Enter Email Address"
                  onChange={this.setEmail}
                />
              </Col>
            </Row>
            <Row className={resetpassword.nowLogInRow}>
              <Col lg={{ span: 4, offset: 0 }}>
                <Link className={resetpassword.nowLogIn} to="/login">
                  Log in your account
                </Link>
              </Col>
              <Col lg={{ span: 5, offset: 3 }}>
                <p className={resetpassword.nowRegister}>
                  Not a member?
                  <Link className={resetpassword.fontQ} to="/signup">
                    Registration
                  </Link>
                </p>
              </Col>
            </Row>
            <Row className={resetpassword.button}>
              <Col lg={{ span: 4, offset: 4 }}>
                <CommonButton type="button" btnText="Reset password" />
              </Col>
            </Row>
          </form>
        </Row>
      </Container>
    );
  }
}

export default Resetpassword;
