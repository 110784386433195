import React, { Component } from "react";
import style from "./singleServiceBox.module.scss";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import {ReactComponent} icon from

class SingleServiceBox extends Component {
  render() {
    return (
      <div>
        <div className={style.singleCounter}>
          <Row>
            <div className={style.icon1}>{this.props.icon}</div>
          </Row>
          <Row className={style.transportContent}>
            <h3 className={style.transportationType}>Road Transport</h3>
            <p className={style.transportationDetail}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
              nostrum minima eligendi neque labore asperiores.
            </p>
            <Link to="#" className={style.readMore}>
              Read More
            </Link>
          </Row>

          {/* <p>{this.props.title}</p> */}
        </div>
        {/* <div className={style.singleServicesBox}>
          

          
        </div> */}
      </div>
    );
  }
}

export default SingleServiceBox;
