import React, { Component } from "react";

//component import
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import index from "./indexpage.module.scss";
import Aboutus2 from "../../UpdatedComponents/aboutUsCompartment/aboutus_2/aboutus_2";
import SingleCounter from "../../UpdatedComponents/aboutUsCompartment/singleCounter/singleCounter";
import Whychooseus from "../../UpdatedComponents/aboutUsCompartment/whychooseus/whychooseus";
import Ourtestimonial from "../../UpdatedComponents/aboutUsCompartment/ourTestimonials/ourtestimonial";
import Shipment from "../../UpdatedComponents/aboutUsCompartment/shipment2/shipment2";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import OurServices from "../../UpdatedComponents/home/ourservices/ourServices";
import BG from "../../UpdatedComponents/home/bg/BG";
import BG2 from "../../UpdatedComponents/home/bg2/BG2";
import DataForm from "../../UpdatedComponents/exioComponents/data/fullcomponent/DataForm";
import Ty from "../../UpdatedComponents/exioComponents/ty/Ty";

class IndexPage extends Component {
  state = { isvisible: false };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  playVideo = (value) => {
    this.setState({
      isvisible: value === false ? value : !this.state.isvisible,
    });
  };
  render() {
    return (
      <>
        <div className={index.container}>
          {/* ---------------mainheader---------------- */}
          <header className={index.realHeader}>
            {/* <MainHeader /> */}
            <Navbar />
          </header>
          {/* ------------------image------------------ */}
          {/* <BG closeVideo={this.playVideo} isvisible={this.state.isvisible} /> */}
          <BG2 />
          {/* <Container> */}
          {/* ------------------aboutus----------------- */}
          {/* <Aboutus2
            closeVideo={this.playVideo}
            isvisible={this.state.isvisible}
          /> */}
          {/* -------------whychooseus----------------- */}
          {/* <Whychooseus /> */}
          {/* -------------singlecounter----------------- */}
          {/* <SingleCounter /> */}
          {/* -------------shipments--------------------- */}
          {/* <Shipment /> */}
          {/* -------------ourtestimonials-------------- */}
          {/* <Ourtestimonial /> */}
          {/* ------------footer---------------------- */}
          {/* <Footer /> */}
          {/* <Ty/> */}
        </div>
      </>
    );
  }
}
export default IndexPage;
