import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import newpasswordpage from "./newpasswordpage.module.scss";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Resetnewpassword from "../../UpdatedComponents/resetnewpassword/resetnewpassword";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";

class Newpasswordpage extends Component {
  state = { isloggedin: true };
  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({ isloggedin: true });
      console.log("token");
    } else {
      this.setState({ isloggedin: false });
    }
  }

  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={newpasswordpage.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image-------------------- */}
        {/*<AboutImg pageName="Reset Password" />*/}
        {/* ---------------LogIn---------------- */}
        <div className={newpasswordpage.newpassword}>
          <Resetnewpassword />
        </div>
        {/* <Footer /> */}
        {!this.state.isloggedin && <Navigate to="/" />}
      </>
    );
  }
}

export default Newpasswordpage;
