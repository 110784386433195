import React, { Component } from "react";
import inputform from "./inputform.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import Inputbox from "../inputbox/inputbox";

class Inputform extends Component {
  render() {
    let { value, setValue, check } = this.props;
    let { checkName, checkEmail, checkPhoneNo, checkMessage } = check;
    let { name, email, phoneNo, message } = value;
    let { setName, setEmail, setPhoneNo, setMessage } = setValue;
    return (
      <div>
        <Container
          style={{
            background: "#fff",
            border: "1px solid #ddd",
            padding: "30px 25px",
            borderRadius: "10px"
          }}
        >
          <Row className={inputform.rowkiClass}>
            <Col>
              <div
                id="personalDetails"
                className={(inputform.row, inputform.contactBox)}
              >
                <div className={inputform.contactBoxHeader}>
                  <h3>Personal Details</h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={inputform.rowkiClass}>
            <Col>
              <Inputbox
                check={checkName}
                disabled={this.props.disabled ? true : false}
                inputValue={name}
                onChange={(e) => {
                  setName(e);
                }}
                label="Full Name"
                type="text"
              />
            </Col>
            <Col>
              <Inputbox
                check={checkPhoneNo}
                disabled={this.props.disabled ? true : false}
                inputValue={phoneNo}
                onChange={(e) => {
                  setPhoneNo(e);
                }}
                label="Phone e.g.: +12345"
                type="text"
              />
            </Col>
          </Row>
          <Row className={inputform.rowkiClass}>
            <Col>
              <Inputbox
                check={checkEmail}
                disabled={this.props.disabled ? true : false}
                inputValue={email}
                onChange={(e) => {
                  setEmail(e);
                }}
                label="Email"
                type="email"
              />
            </Col>
          </Row>
          <Row className={inputform.rowkiClass}>
            <Col>
            <label>Messages: </label>
              <textarea
                //check={checkMessage}
                disabled={this.props.disabled ? true : false}
                inputValue={message}
                style={{width:"100%",height:"auto", minHeight: "100px",resize: "none",border: "1px solid #dfdfdf",borderRadius: "0.25rem",padding: "0.75rem 1.1rem"}}
                rows={3}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                label="Message"
                type="textarea"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Inputform;
