import React, { Component } from "react";
import Cbtn from "./CommonButton.module.scss";
import LinkButton from "../linkbutton/linkbutton";

class CommonButton extends Component {
  render() {
    return (
      <div style={{ width: this.props.width ? this.props.width : "auto" }}>
        <LinkButton
          to={this.props.to ? this.props.to : "#"}
          style={{
            ...this.props.style,
            width: this.props.width ? this.props.width : "100%",
            borderRadius: this.props.borderRadius
              ? this.props.borderRadius
              : "22px",
            // backgroundColor: this.props.backgroundColor?this.props.backgroundColor:"#0b8dd4",
            // color: this.props.btnTextColor?this.props.btnTextColor:"#fff",
            // borderColor: this.props.btnBorderColor?this.props.btnBorderColor:"#0b8dd4"
          }}
          className={this.props.nav ? Cbtn.btn : `${Cbtn.btn} ${Cbtn.nav}`}
          type={this.props.type}
          onClick={this.props.onClick ? this.props.onClick : () => {}}
          scroll={this.props.scroll}
          behavior={this.props.behavior}
        >
          <span>{this.props.btnText}</span>
        </LinkButton>
      </div>
    );
  }
}

export default CommonButton;
