import React, { Component } from "react";
import "boxicons";
import axios from "axios";

//grid import
import { Col, Row, Container } from "react-bootstrap";
//scss import
import mainheader from "./MainHeader.module.scss";
//component import
import Navbar from "../nav/Navbar";
import Loginform from "../../loginform/loginform";
//icon import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Usa from "../../../resources/img/usa.png";

//svg import
import { ReactComponent as Call } from "../../../resources/phone-call.svg";
import { Link } from "react-router-dom";
class MainHeader extends Component {
  state = { isloggedin: false };

  componentDidMount = async () => {
    if (localStorage.getItem("token")) {
      let res = await axios.get(
        `https://admin.loadpool.co.za/api/v1/user/getProfile?token=${localStorage.getItem(
          "token"
        )}`
      );
      if (res.data.statusCode === 200) {
        this.setState({ isloggedin: true });
      }
    }
  };
  render() {
    return (
      <div className={mainheader.header}>
        <Container>
          <Row className={`${mainheader.upperRow} align-items-center`}>
            <Col
              lg={{ span: 8, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              // style={{ width: "20%" }}
              className={mainheader.firstColumn}
            >
              <ul className={mainheader.leftContent}>
                <li>
                  <span>
                    <box-icon size="18px" name="home" color="#fff"></box-icon>
                  </span>
                  9170 Millbrook Rd, Newark, IL 60541
                </li>
                <li>
                  <span>
                    <box-icon
                      className={mainheader.hello}
                      size="18px"
                      name="phone-call"
                      color="#fff"
                    ></box-icon>
                  </span>
                  <Link to="#">+1 (123) 456 7890</Link>
                </li>
                <li>
                  <span>
                    <box-icon
                      size="18px"
                      name="envelope"
                      color="#fff"
                    ></box-icon>
                  </span>
                  hello@example.com
                </li>
              </ul>
            </Col>
            <Col className={mainheader.rightContent} lg="4" md="2">
              <ul className={mainheader.flagArea}>
                <li className={mainheader.flagItemTop}>
                  <Link to="/login" className={mainheader.flagBar}>
                    {!this.state.isloggedin ? (
                      <span>Login</span>
                    ) : (
                      <span
                        onClick={() => {
                          axios
                            .post(
                              "https://admin.loadpool.co.za/api/v1/user/logout"
                            )
                            .then((res) => {
                              localStorage.clear();
                              console.log(res);
                              this.setState({ isloggedin: false });
                              window.location.reload();
                            });
                        }}
                      >
                        Logout
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainHeader;
