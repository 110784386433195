import React, { Component } from "react";
import { Link } from "react-router-dom";
import image from "../../../resources/LoadpoolImages/homepage-banner.jpg";

// scss import
import aboutHead from "./AboutImg.module.scss";

export class AboutImg extends Component {
  render() {
    let { bgImage } = this.props;
    let a = bgImage ? bgImage : image;
    return (
      <>
        <div
          style={{
            background: `url(${a}) center top/cover`,
          }}
          className={aboutHead.aboutbg}
        >
          <h1> {this.props.pageName}</h1>
          <ul className={aboutHead.activePage}>
            <li className={aboutHead.slash}>
              <Link to="/">Home</Link>
            </li>
            <li>{this.props.pageName}</li>
          </ul>
        </div>
      </>
    );
  }
}

export default AboutImg;
