import React, { Component } from "react";
import { Link } from "react-router-dom";

import registerForm from "./register.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import Labelbox from "../exioComponents/labelbox/labelbox";
import axios from "axios";
import CommonButton from "../exioComponents/commonButton/CommonButton";
import { Navigate, Redirect } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sendData = async (obj, url) => {
  let res = await axios({
    method: "post",
    url: url,
    headers: {
      "content-type": "application/json;charset=utf-8",
    },
    data: obj,
  });
  console.log(res.data);
  return res.data;
};

class Register extends Component {
  state = {
    validated: false,
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    businessName: "",
    showPass: false,
    error: "",
    registerSuccess: false,
  };

  notify = () => {
    toast.error(this.state.error, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  setFirstName = (value) => {
    this.setState({
      firstName: value,
    });
  };
  setLastName = (value) => {
    this.setState({
      lastName: value,
    });
  };

  setBusinessName = (value) => {
    this.setState({
      businessName: value,
    });
  };
  setEmail = (value) => {
    this.setState({
      email: value,
    });
  };
  setPhoneNo = (value) => {
    if (Number(value) || value === "") {
      this.setState({
        phoneNo: value,
      });
    }
  };
  setPassword = (value) => {
    this.setState({
      password: value,
    });
  };

  handleSubmit = async (event) => {
  	/*toast("You have been registered successfully.");
    setTimeout(()=> this.setState({ registerSuccess: true }), 3000); */
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    else {
    const { firstName, lastName, businessName, email, password, phoneNo } =
      this.state;
    let name = `${firstName} ${lastName}`;
    let res = await sendData(
      { name, email, businessName, password, phoneNo },
      "https://admin.loadpool.co.za/api/v1/user/register"
    );
    if (res.statusCode === 201) {
    	localStorage.setItem("isRegister", true);
    	this.setState({ registerSuccess: true });
    	//toast("You have been registered successfully.");setTimeout(()=> this.setState({ registerSuccess: true }), 3000);
    } else {
      if (res.msg === "User Already Exist. Please Login") {
        this.setState({ registerSuccess: false, error: res.msg }, () => {
          this.notify();
        });
      }
    }
    }
    this.setState({ validated: true });
  };
  render() {
    return (
      <>
        <ToastContainer className={registerForm.settingToast} />

        <Container
          style={{
            maxWidth: "650px",
            padding: "20px",
            boxShadow: "0 0 20px 3px rgba(24, 23, 23, 0.05)",
            marginBottom: "100px"
          }}
        >
          <Row>
            <Col className={registerForm.accountTitle}>
              <h2 className={registerForm.accountHead}>Credit Registration For Corporates</h2>
            </Col>
          </Row>
          <Row className={registerForm.description}>
            <Col>
            If you are a corporate company or business and would like to apply for a credit facility, please complete the registration details below and one of our agents will connect with you.
            </Col>
          </Row>
          <Row>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Row className={registerForm.formGroup}>
                <Labelbox
                  formlabel="Contact Person Name"
                  feedback={{ type: "invalid", content: "Required" }}
                  id="111"
                  required
                  value={this.state.firstName}
                  setValue={this.setFirstName}
                />
                <Labelbox
                  formlabel="Contact Person Surname"
                  feedback={{ type: "invalid", content: "Required" }}
                  id="112"
                  required
                  value={this.state.lastName}
                  setValue={this.setLastName}
                />
                <Labelbox
                  formlabel="Business Name(if any)"
                  feedback={{ type: "invalid", content: "Required" }}
                  id="113"
                  value={this.state.businessName}
                  setValue={this.setBusinessName}
                />
                <Labelbox
                  formlabel="Email Address"
                  feedback={{ type: "invalid", content: "Check Email" }}
                  id="22"
                  required={true}
                  value={this.state.email}
                  setValue={this.setEmail}
                  type="email"
                />
                <Labelbox
                  formlabel="Mobile No."
                  feedback={{ type: "invalid", content: "Wrong Phone Number" }}
                  id="33"
                  required={true}
                  value={this.state.phoneNo}
                  setValue={this.setPhoneNo}
                  minLength={10}
                  maxLength={10}
                />
                <Labelbox
                  formlabel="Password"
                  feedback={{
                    type: "invalid",
                    content: "Write a Long Password",
                  }}
                  id="44"
                  required={true}
                  value={this.state.password}
                  setValue={this.setPassword}
                  type={this.state.showPass ? "text" : "password"}
                  minLength={4}
                />
              </Row>
              <Row className={registerForm.loginBtn}>
                <Col>
                  <CommonButton type="submit" btnText="Register now" />
                </Col>
                <Col align="right">
                  <span className={registerForm.logRem}>
                    <input
                      id="rememberK"
                      type="checkbox"
                      onClick={() => {
                        this.setState({ showPass: !this.state.showPass });
                      }}
                    />
                    <label htmlFor="remember">Show Password?</label>
                  </span>
                </Col>
              </Row>
              <Row className={registerForm.lastrow}>
                <p className={registerForm.lastrowpara}>
                  Have an account?{" "}
                  <Link to="/login" className={registerForm.lastrowanchor}>
                    Login Now!
                  </Link>
                </p>
              </Row>
            </Form>
          </Row>
          {this.state.registerSuccess && <Navigate to="/login" />}
        </Container>
      </>
    );
  }
}

export default Register;
