import React, { Component } from "react";
import DropStyle from "./Dropdown.scss";
import $ from "jquery";
import Validationbox from "../validationbox/validationbox";
import { Row } from "react-bootstrap";
window.jQuery = window.$ = $;
require("jquery-nice-select");

class Dropdown extends Component {
  myRef = React.createRef();
  myOptions = [];
  componentDidMount() {
    $(this.myRef.current).niceSelect();
    $(this.myRef.current).val(this.props.value).niceSelect("update");

    $(this.myRef.current).on("change", (e) => {
      this.props.setValue(e.target.value);
    });
    if (typeof this.props.value == "number") {
      $(this.myOptions[this.props.value].current).attr("selected", "selected");
    }
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.options[0]) {
      if (prevProps.options[0]) {
        $(this.myRef.current).niceSelect("update");
      }
    }
  };

  printOptions = () => {
    return this.props.options.map((ele, i) => {
      if (ele) {
        if (this.props.ObjectDropDown) {
          const tempRef = React.createRef();
          this.myOptions.push(tempRef);
          if (ele.desc) {
            return (
              <option
                selected={
                  this.props.value.toUpperCase() === ele.name.toUpperCase()
                    ? true
                    : false
                }
                ref={tempRef}
                key={i}
                value={i}
              >
                {ele.name} - {ele.desc}
              </option>
            );
          } else {
            return (
              <option
                selected={this.props.value === ele.name ? true : false}
                ref={tempRef}
                key={i}
                value={i}
              >
                {ele.name}
              </option>
            );
          }
        } else {
          const tempRef = React.createRef();
          this.myOptions.push(tempRef);
          return (
            <option ref={tempRef} key={i} value={i}>
              {ele}
            </option>
          );
        }
      }
    });
  };

  checkSet = () => {
    let { check } = this.props;
    if (!check) check = {};
    check.style = { display: "none" };
    return null;
  };

  render() {
    let { check, id } = this.props;
    if (!check) check = {};
    return (
      <>
        <div style={{ display: "flex" }}>
          <select
            id={id}
            className="wide price-list"
            name={id}
            ref={this.myRef}
          >
            {this.printOptions()}
          </select>
        </div>
        <Validationbox
          text={
            check
              ? check.text
                ? check.text
                : this.checkSet()
              : "Order is empty."
          }
          style={check ? (check.style ? check.style : {}) : {}}
        />
      </>
    );
  }
}

export default Dropdown;
