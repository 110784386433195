import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../ourTestimonials/ourtestimonial.module.scss";
import SingleTestimonial from "../singleTestimonial/singleAbout";
import CommonButton from "../../exioComponents/commonButton/CommonButton";
import aboutus_2 from "./aboutus_2.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import AboutUsImg from "../../../resources/LoadpoolImages/image.jpg";
// --------------carousel---------------------
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import profileimg from "../../../resources/img/8.png";
import one from "../../../resources/img/7.png";
import two from "../../../resources/img/9.png";
import three from "../../../resources/img/10.png";

class Aboutus2 extends Component {
  state = { owl: 3 };

  componentDidMount = () => {
    if (window.innerWidth < 960) {
      this.setState({ owl: 1 });
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 960) {
        this.setState({ owl: 1 });
      }
    });
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", () => {
      if (window.innerWidth < 960) {
        this.setState({ owl: 1 });
      }
    });
  };
  render() {
    return (
      <>
        <div className={styles.container} style={{ overflowX: "hidden" }}>
          <Row className={styles.row}>
            {/* <p className={styles.testimonialPara}>USE US</p> */}
            <h1 className={styles.testimonialHead}>
              Our Concept
            </h1>
          </Row>
          <Row className="row conceptrow">
            <OwlCarousel
              items={2}
              autoplay={false}
              loop={false}
              nav={false}
              dots={false}
              center={false}
              mouseDrag={false}
              touchDrag={false}
              autoHeight={true}
              margin={0}
              smartSpeed={500}
              URLhashListener={true}
              autoplayHoverPause={true}
              responsive={false}
              
              navText={["<img src='arrow-right.png'/>","<img src='arrow-left.png'/>"]}
              
            >
              <Col>
                <SingleTestimonial
                  heading="Why courier It when you can loadpool it?"
                  paragraph="We are a national transporter operating on set routes thereby frequenting all major cities throughout South Africa at least 2 -3 times per week. We often travel below our capacity thereby availing space for a few additional smaller loads. We are going there anyway, so why not loadpool it with us and save. Herein lies the market gap and the reason why we can offer you unparalleled rates and efficiency. In the same manner that people often carpool to and from work to save on costs, so too will you save when you loadpool it with us."
                  photu={three}
                  isulshow={true}
                />
              </Col>
              <Col>
                <SingleTestimonial
                  heading="Tired of being asked Length, Breadth, Height & Weight for your goods?"
                  paragraph="Having a national footprint ourselves, we’ve often sought the services of Courier Company’s only to be confronted with the daunting interrogation of L, B, W & H of our goods. Seriously, we're certain that none of us readily have this information at hand? More often than not, when one eventually receives the quote, it's usually higher than the value of the goods being transported. Loadpool has removed the frustration of L, B, W & H of goods via the use of illustrated images."
                  photu={one}
                />
              </Col>
            </OwlCarousel>
          </Row>
        </div>
      </>
    );
  }
}

export default Aboutus2;
