import React, { Component } from "react";
import AboutusOdometer from "../odometersec/odometer";
import { Container, Row, Col } from "react-bootstrap";
import singleCounter from "./singleCounter.module.scss";
import { ReactComponent as Package } from "../../../resources/svg/package.svg";
import { ReactComponent as DeliveryBox } from "../../../resources/svg/delivery-box.svg";
import { ReactComponent as UserIcon } from "../../../resources/svg/user-_2_.svg";
import { ReactComponent as WorldWide } from "../../../resources/svg/worldwide.svg";

class SingleCounter extends Component {
  render() {
    return (
      <div>
        <div className={singleCounter.container}>
          <Container className={singleCounter.section}>
            <Row className={singleCounter.row}>
              <Col lg="3">
                <AboutusOdometer
                  value={950}
                  icon={<Package style={{ height: "45px", width: "45px" }} />}
                  setHandler={this.setSvgclassName}
                  title="Delivered Packages"
                />
              </Col>
              <Col lg="3">
                <AboutusOdometer
                  value={850}
                  icon={<WorldWide style={{ height: "45px", width: "45px" }} />}
                  title="Countries Covered"
                />
              </Col>
              <Col lg="3">
                <AboutusOdometer
                  value={550}
                  icon={<UserIcon style={{ height: "45px", width: "45px" }} />}
                  title="Happy Clients"
                />
              </Col>
              <Col lg="3">
                <AboutusOdometer
                  value={440}
                  icon={
                    <DeliveryBox style={{ height: "45px", width: "45px" }} />
                  }
                  title="Tons Of Goods"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default SingleCounter;
