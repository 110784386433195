import React, { Component } from "react";
import "./button.scss";
import { Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

class SubmitButton extends Component {
  render() {
    let { onClick } = this.props;
    return (
      <div>
        <Row>
          <Col lg={12}>
            <button
              type="submit"
              name="submit"
              onClick={onClick ? onClick : () => {}}
              className="btn-form-func"
              style={{
                background: this.props.background,
                color: this.props.color,
              }}
            >
              <span className="btn-form-func-content">{this.props.value}</span>
              <div
                className="icon"
                style={{
                  borderLeft: this.props.leftborder
                    ? `${this.props.leftborder} !important`
                    : "auto",
                }}
              >
                <i
                  style={{ color: this.props.colorprop }}
                  className={this.props.tick}
                  aria-hidden="true"
                ></i>
              </div>
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SubmitButton;
