import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const LinkButton = (props) => {
  let navigate = useNavigate();
  const {
    match,
    staticContext,
    to,
    onClick,
    scroll,
    behavior,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;
  return (
    <button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event);
        if (to !== "#") {
          console.log(to);
          if (scroll) {
            window.scrollTo({
              top: 0,
              behavior: behavior ? behavior : "smooth",
            });
            navigate(to, {
              state: {
                from: 0,
              },
            });
          } else {
            navigate(to, {
              state: {
                from: 0,
              },
            });
          }
        }
      }}
    />
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkButton;
