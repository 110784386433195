import React, { Component } from "react";
import "./booknow.scss";

class Booknownav extends Component {
  render() {
    return (
      <div className="paddintTop">
        <div className="subheader">
          <div className="bookncontainer">
            <h1 className="bookNav">BOOKING DETAILS</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Booknownav;
