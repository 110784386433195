import React, { Component } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import md5 from "md5";

// components
import Booknownav from "../../UpdatedComponents/components/booknownav/booknownav";
import Mainbox from "../../UpdatedComponents/components/mainbox/Mainbox";
import bookingform from "./bookingformpage.module.scss";
import Dropdown from "../../UpdatedComponents/components/dropdown/Dropdown";
import BookingFormSelect from "../../UpdatedComponents/bookingformSelect/bookingformSelect";
import Slidernew from "../../UpdatedComponents/components/slider/Slider";
import Inputform from "../../UpdatedComponents/components/inputform/inputform";
import Checkbox from "../../UpdatedComponents/components/checkbox/Checkbox";
import Ordersummary from "../../UpdatedComponents/components/ordersummary/ordersummary";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Ty from "../../UpdatedComponents/exioComponents/ty/Ty";
import Inputbox from "../../UpdatedComponents/components/inputbox/inputbox";
// img
import image1 from "../../resources/img/img-1.png";
import image2 from "../../resources/img/img-2.png";
import image3 from "../../resources/img/img-3.png";
import image4 from "../../resources/img/img-4.png";

//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router";

window.jQuery = window.$ = $;
require("jquery-nice-select");
const google = window.google;

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = typeof val1 === "object" && typeof val2 === "object";
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

const selProducts = (data) => {
	let selprotitle = '';
	if (data)
	{
		let tempList = data.map((ele, i) => {
        	if (ele)
        	{
        		if(selprotitle == "")
        		{
        			selprotitle = ele.title;
        		}
        		else
        		{
					selprotitle = selprotitle.concat(", ", ele.title);
				}
        	}
    	});
    }
    return selprotitle;
}
const generateSignature = (payFastDatas = null) => {
    const params = new URLSearchParams(payFastDatas);
    const MD5Signature = md5(params.toString())
    return MD5Signature;
}

const payFast = async () => {
  //Live: https://www.payfast.co.za/onsite/process
  //Sandbox: https://sandbox.payfast.co.za/onsite/process
  let payFastData = {
  	merchant_id: "17778925",
  	merchant_key: "ybbjle24a2s5a",
  	amount: "100.00",
  	item_name: "Test Product",
  	email_address: "jasvirsingh10@yahoo.co.in"
  }
  let res = await axios({
    method: "post",
    url: "https://sandbox.payfast.co.za/onsite/process",
    headers: {
      "content-type": "application/json;charset=utf-8",
      "x-access-token": localStorage.getItem("token"),
    },
    data: payFastData,
  });
  console.log(res.data);
  return res.data;
};

const sendData = async (obj, url) => {
  let res = await axios({
    method: "post",
    url: url,
    headers: {
      "content-type": "application/json;charset=utf-8",
      "x-access-token": localStorage.getItem("token"),
    },
    data: obj,
  });
  // console.log(res.data);
  return res.data;
};

class Bookingformpage extends Component {



  state = {
    selProducts: [],
  	//signature:generateSignature(null),
    isLoggedIn: true,
    thankyou: false,
    isValidated: false,
    orderSuccess: false,
    from: 0,
    to: 0,
    dataEntry: ["", "", "", ""],
    cities: [],
    deliveryType: [false, false],
    pickupType: [false, false],
    dropOffType: [false, false],
    products: [
      { name: "45cm x 30cm", desc: "STANDARD BOX", price: 500, img: image1 },
      {
        name: "85cm X 60cm",
        desc: "STANDARD WASHING MACHINE",
        price: 750,
        img: image2,
      },
      {
        name: "180cm X 81cm",
        desc: "STANDARD SINGLE DOOR FRIDGE",
        price: 1000,
        img: image3,
      },
      {
        name: "200cm X 91cm",
        desc: "STANDARD DOUBLE DOOR FRIDGE",
        price: 1200,
        img: image4,
      },
    ],
    pickupAddress: "",
    pickupAddressData: {},
    dropOffAddress: "",
    dropOffAddressData: {},
    isInsured: [false, false],
    valueOfGoods: "",
    selectSize: ["", "", "", ""],
    tnc: false,
    illegal: false,
    sliderValue: [0, 0, 0, 0],
    personalDetails: {
      name: "",
      email: "",
      phoneNo: "",
      addresses: [],
      message: "",
    },
    error: {
      from: "",
      to: "",
      dataEntry: "",
      deliveryType: ["", ""],
      pickupType: ["", ""],
      dropOffType: ["", ""],
      pickupAddress: "",
      dropOffAddress: "",
      tnc: "",
      illegal: "",
      isInsured: ["", ""],
      valueOfGoods: "",
      sliderValue: ["", "", "", ""],
      personalDetails: {
        name: "",
        email: "",
        phoneNo: "",
        address: "",
        message: "",
      },
    },
  };

  notify = () => {
    toast.success("We got your order, we will proceed as soon as possible!");
    this.setState({
      thankyou: true,
    });
  };
  notifyErr = () => {
    // toast.error("Some Error Occured, Please check your details");
  };

  // ======================================================================================= //
  // Set state functions //

  setFrom = (value) => {
    this.setState({ from: value }, () => {
      console.log(this.state.from, this.state.cities[this.state.from - 1]);
    });
  };
  setTo = (value) => {
    this.setState({ to: value }, () => {
      console.log(this.state.to, this.state.cities[this.state.to - 1]);
    });
  };

  setDataEntry = (value) => {
    // console.log(value);
    this.setState({ dataEntry: [...this.state.dataEntry, value] });
  };

  setSelectSize = (value, i) => {
    let temp = [...this.state.selectSize];
    temp[i] = value;
    this.setState({ selectSize: temp }, () => {
      this.productPush();
    });
  };

  setSliderValue = (value, i) => {
    let temp = [...this.state.sliderValue];
    temp[i] = Number(value);
    this.setState({ sliderValue: temp }, () => {
      this.productPush();
    });
  };

  setDeliveryType = (value, index) => {
    switch (index) {
      case "0":
        if (value) {
          this.setState({
            deliveryType: [true, false],
          });
        } else {
          this.setState({ deliveryType: [false, false] });
        }
        break;
      case "1":
        if (value) {
          this.setState({ deliveryType: [false, true] });
        } else {
          this.setState({ deliveryType: [false, false] });
        }
        break;
      default:
        break;
    }
  };

  setDropOffType = (value, index, test) => {
    switch (index) {
      case "0":
        this.setState({ dropOffType: [true, false] }, () => {
          if (test) test(value);
        });

        break;
      case "1":
        this.setState({ dropOffType: [false, true] }, () => {
          if (test) test(value);
        });

        break;
      default:
        break;
    }
  };

  setPickupType = (value, index, test) => {
    switch (index) {
      case "0":
        this.setState({ pickupType: [true, false] }, () => {
          if (test) test(value);
        });

        break;
      case "1":
        this.setState({ pickupType: [false, true] }, () => {
          if (test) test(value);
        });

        break;
      default:
        break;
    }
  };
  setDropOffAddress = (value) => {
    this.setState({ dropOffAddress: value });
    return value;
  };

  setPickupAddress = (value) => {
    this.setState({ pickupAddress: value });
    return value;
  };

  setPickupAddressData = (value) => {
    this.setState({ pickupAddressData: value });
    console.log(this.state.pickupAddressData);
    return value;
  };
  setDropOffAddressData = (value) => {
    this.setState({ dropOffAddressData: value });
    return value;
  };

  setIsInsured = (value, index) => {
    switch (index) {
      case "0":
        if (value) {
          this.setState({ isInsured: [true, false] });
        } else {
          this.setState({ isInsured: [false, false] });
        }
        break;
      case "1":
        if (value) {
          this.setState({ isInsured: [false, true] });
        } else {
          this.setState({ isInsured: [false, false] });
        }
        break;
      default:
        break;
    }
  };

  setPersonalDetails = () => {
    let setName = (value) => {
      this.setState({
        personalDetails: {
          ...this.state.personalDetails,
          name: value,
        },
      });
    };
    let setEmail = (value) => {
      this.setState({
        personalDetails: {
          ...this.state.personalDetails,
          email: value,
        },
      });
    };
    let setPhoneNo = (value) => {
      if (Number(value) || value === "") {
        this.setState({
          personalDetails: {
            ...this.state.personalDetails,
            phoneNo: value,
          },
        });
      }
    };
    let setAddress = (value) => {
      this.setState({
        personalDetails: {
          ...this.state.personalDetails,
          address: { formatted_address: value },
        },
      });
    };
    let setMessage = (value) => {
      this.setState({
        personalDetails: {
          ...this.state.personalDetails,
          message: value,
        },
      });
    };
    return { setName, setEmail, setPhoneNo, setAddress, setMessage };
  };

  setValueOfGoods = (value) => {
    if (Number(value) || value === "") {
      this.setState({ valueOfGoods: value });
    }
  };
  setTnc = (value, index) => {
    this.setState({ tnc: value });
  };
  setIllegal = (value, index) => {
    this.setState({ illegal: value });
  };
  // ======================================================================================= //
  // Enter Product functions //

  productPush = () => {
    const { dataEntry, sliderValue, selectSize, products, selProducts } = this.state;
    let temp = dataEntry;
	let selTemp = [];
    // console.log("++++++++++++++++++++++++++++");
    selectSize.forEach((item, i) => {

      if (item) {
        temp[i] = {
          size: item,
          number: sliderValue[i],
          price: products[i].price,
          title: products[i].desc + " ("+products[i].name+")"
        };
        selTemp[i] = products[i].desc + " ("+products[i].name+")";
        this.setState({dataEntry: temp, selProducts: selTemp});
      } else {
        this.productDrop(i);
      }
    });
  };

  productDrop = (index) => {
    let temp = this.state.dataEntry;
    temp[index] = "";
    var selTemp = this.state.selProducts;
    selTemp = selTemp.splice(index, 1);
    let temp2 = [...this.state.sliderValue];
    temp2[index] = Number(0);
    let temp3 = [...this.state.selectSize];
    temp3[index] = "";
    this.setState({ dataEntry: temp, selProducts: selTemp, sliderValue: temp2, selectSize: temp3 });
  };

  // ======================================================================================= //
  // Handle Sumbit functions //

  handleSubmit = async (e) => {
    e.preventDefault();
    this.resetErrors();
    let checkErr = {
      from: "",
      to: "",
      dataEntry: "",
      dataEntry2: "",
      deliveryType: ["", ""],
      pickupType: ["", ""],
      dropOffType: ["", ""],
      pickupAddress: "",
      dropOffAddress: "",
      tnc: "",
      illegal: "",
      isInsured: ["", ""],
      valueOfGoods: "",
      sliderValue: ["", "", "", ""],
      personalDetails: {
        name: "",
        email: "",
        phoneNo: "",
        address: "",
        message: "",
      },
    };
    this.setState({ isValidated: false });
    let {
      from,
      to,
      dataEntry,
      deliveryType,
      pickupType,
      dropOffType,
      dropOffAddress,
      pickupAddress,
      pickupAddressData,
      dropOffAddressData,
      isInsured,
      valueOfGoods,
      tnc,
      illegal,
      sliderValue,
      personalDetails,
    } = this.state;

    const { name, email, address, message, phoneNo } = personalDetails;

    let errorsTemp = {
      from: "",
      to: "",
      dataEntry: "",
      dataEntry2: "",
      deliveryType: ["", ""],
      pickupType: ["", ""],
      dropOffType: ["", ""],
      pickupAddress: "",
      dropOffAddress: "",
      tnc: "",
      illegal: "",
      isInsured: ["", ""],
      valueOfGoods: "",
      sliderValue: ["", "", "", ""],
      personalDetails: {
        name: "",
        email: "",
        phoneNo: "",
        address: "",
        message: "",
      },
    };

    // ======================================================================================= //
    // Error handling functions //



    // personal form
    if (!this.state.isLoggedIn) {
      if (!name) {
        errorsTemp.personalDetails.name = "Enter the Field";
        this.setState({ isValidated: false });
      } else if (!email) {
        errorsTemp.personalDetails.email = "Enter the Field";
        this.setState({ isValidated: false });
      } else if (!phoneNo) {
        errorsTemp.personalDetails.phoneNo = "Enter the Field";
        this.setState({ isValidated: false });
      } else if (!message) {
        //errorsTemp.personalDetails.message = "Enter the Field";
        //this.setState({ isValidated: false });
      }
    }
    //insurance
    if (!isInsured[0] && !isInsured[1]) {
      errorsTemp.isInsured = ["Choose one Option!", ""];
      this.setState({ isValidated: false });
    } else if (isInsured[0]) {
      if (!valueOfGoods) {
        errorsTemp.valueOfGoods = "Enter The value";
        this.setState({ isValidated: false });
      }
    }

    //delivery type
    if (!deliveryType[0] && !deliveryType[1]) {
      errorsTemp.deliveryType = ["Choose one Option!", ""];
      this.setState({ isValidated: false });
    }

    // pickup type
    if (!pickupType[0] && !pickupType[1]) {
      errorsTemp.pickupType = ["Choose one Option!", ""];
      this.setState({ isValidated: false });
    } else if (pickupType[0]) {
      if (!pickupAddress) {
        errorsTemp.pickupAddress = "Enter The Address";
        this.setState({ isValidated: false });
      } else if (!Object.keys(pickupAddressData)[0]) {
        errorsTemp.pickupAddress = "Choose the Address from suggestions";
        this.setState({ isValidated: false });
      } else {
        let checkNew = false;
        if (this.state.from > 0) {
          // this.state.pickupAddressData.address_components.forEach((parts) => {
          //   console.log(
          //     parts.long_name
          //       .toLowerCase()
          //       .includes(this.state.cities[this.state.from - 1].toLowerCase()),
          //     parts.long_name.toLowerCase(),
          //     this.state.cities[this.state.from - 1].toLowerCase()
          //   );
            // if (
            //   parts.long_name
            //     .toLowerCase()
            //     .includes(this.state.cities[this.state.from - 1].toLowerCase())
            // ) {
            //   checkNew = true;
            // }
          // });
          if (false && !checkNew) {
            errorsTemp.pickupAddress = `The Address is not in ${
              this.state.cities[this.state.from - 1]
            } `;
            this.setState({ isValidated: false });
          } else {
            errorsTemp.pickupAddress = "";
          }
        }
      }
    }
    if (!dropOffType[0] && !dropOffType[1]) {
      errorsTemp.dropOffType = ["Choose one Option!", ""];
      this.setState({ isValidated: false });
    } else if (dropOffType[0]) {
      if (!dropOffAddress) {
        errorsTemp.dropOffAddress = "Enter The Address";
        this.setState({ isValidated: false });
      } else if (!Object.keys(dropOffAddressData)[0]) {
        errorsTemp.dropOffAddress = "Choose the Address from suggestions";
        this.setState({ isValidated: false });
      } else {
        if (this.state.to > 0) {
          let checkNew = false;
          // console.log(this.state.dropOffAddressData.address_components);
          // this.state.dropOffAddressData.address_components.forEach((parts) => {
          //   console.log(
          //     parts.long_name
          //       .toLowerCase()
          //       .includes(this.state.cities[this.state.to - 1].toLowerCase()),
          //     parts.long_name.toLowerCase(),
          //     this.state.cities[this.state.to - 1].toLowerCase()
          //   );
            // if (
            //   parts.long_name
            //     .toLowerCase()
            //     .includes(this.state.cities[this.state.to - 1].toLowerCase())
            // ) {
            //   checkNew = true;
            // }
          // });
          if (false && !checkNew) {
            errorsTemp.dropOffAddress = `The Address is not in ${
              this.state.cities[this.state.to - 1]
            } `;
            this.setState({ isValidated: false });
          } else {
            errorsTemp.dropOffAddress = "";
          }
        }
      }
    }

    // t N c
    if (!tnc) {
      errorsTemp.tnc = "Please Accept Terms And Conditions!";
      this.setState({ isValidated: false });
    }
    if (!illegal) {
      errorsTemp.illegal = "Please Accept Terms And Conditions!";
      this.setState({ isValidated: false });
    }

    // data
    if (!(dataEntry[0] || dataEntry[1] || dataEntry[2] || dataEntry[3])) {
      errorsTemp.dataEntry = "Enter Items you want to transfer!";
      this.setState({ isValidated: false });
    } else {

      dataEntry.forEach((item, i) => {
        if (item) {
          if (!sliderValue[i]) {
            errorsTemp.sliderValue[i] = "Enter number of items of this size!";
            errorsTemp.dataEntry2 = "Enter Items you want to transfer!";
            this.setState({ isValidated: false });
          }
        }
      });
    }

    // city select

    if (from == 0) {
      errorsTemp.from = "Select Your City";
      this.setState({ isValidated: false });
    } else if (to == 0) {
      errorsTemp.to = "Select Destination";
      errorsTemp.from = "";
      this.setState({ isValidated: false });
    } else if (from === to) {
      errorsTemp.from = "Different Cities Please!";
      errorsTemp.to = "Different Cities Please!";
      this.setState({ isValidated: false });
    } else {
      errorsTemp.from = "";
      errorsTemp.to = "";
      this.setState({ isValidated: false });
    }

    if (deepEqual(checkErr, errorsTemp)) {
      this.setState({ isValidated: true }, async () => {
        if (this.state.isValidated) {
          let res = await this.sendForm();
          if (res.statusCode === 201) {
             console.log(res.data._id);
             let total = 0;
			 //-----------
			 if(this.state.isInsured[1])
			 {
				 this.state.valueOfGoods="";
			 }
			 //---------
			 if (this.state.dataEntry) {
			   this.state.dataEntry.forEach((ele) => {
				 if (ele) {
				   total += Number(ele.number) * Number(ele.price);
				 }
			   });
			 }
			 if (this.state.pickupType[0]) {
			   total += 500;
			 }
			 if (this.state.dropOffType[0]) {
			   total += 500;
			 }
			 if (this.state.deliveryType[0]) {
			   total += 500;
			 }
			 if (this.state.isInsured[0]) {
			   if (this.state.valueOfGoods) {
				 total += (Number(this.state.valueOfGoods) * 3) / 100;
			   }
			 }
  			document.getElementById("orderID").value = res.data._id;
  			document.getElementById("signature").value = generateSignature({merchant_id:"17778925", merchant_key:"ybbjle24a2s5a", return_url:"https://loadpool.co.za/payment-success", cancel_url:"https://loadpool.co.za/payment-cancel", notify_url:"https://admin.loadpool.co.za/api/v1/order/notifyOrder", email_address:this.state.personalDetails.email, m_payment_id:res.data._id, amount:total, item_name:selProducts(this.state.dataEntry), passphrase:"Goodearthwebsites22"});
  			document.getElementById("myForm").submit();
            //this.setState({ orderSuccess: true, isValidated: false });
            //this.notify();
            //this.handleReset();

          } else {
            this.setState({ orderSuccess: false, isValidated: false });
          }
        }
      });
    } else {
      //alert('Please fill in all the highlighted fields');
      toast.error("Please fill in all the highlighted fields");
      this.notifyErr();
      this.setState({ error: errorsTemp, isValidated: false });
    }
  };

  sendForm = async () => {
    let {
      from,
      isValidated,
      to,
      dataEntry,
      deliveryType,
      pickupType,
      dropOffType,
      dropOffAddressData,
      pickupAddressData,
      isInsured,
      valueOfGoods,
      personalDetails,
    } = this.state;

    const { name, email, message, phoneNo } = personalDetails;

    if (isValidated) {
      let totalCost = this.getTotal();
      let goods = [];
      dataEntry.forEach((ele) => {
        goods.push({
          type: ele.size,
          quantity: ele.number,
          price: ele.price,
        });
      });
      // console.log("123");
      // console.log(this.state);
	  //let ress = await payFast();
      let res = await sendData(
        {
          name,
          email,
          phoneNo,
          comments: message,
          from: this.state.cities[this.state.from - 1],
          to: this.state.cities[this.state.to - 1],
          goods,
          pickup: pickupType[0] ? true : false,
          dropOff: dropOffType[0] ? true : false,
          deliveryType: deliveryType[0] ? "express" : "standard",
          insurance: isInsured[0] ? true : false,
          valueOfGoods,
          insuranceCost: (valueOfGoods * 3) / 100,
          bubbleWrap: true,
          totalCost,
          pickupAddress: {
            address: pickupType[0]
              ? pickupAddressData
              : { formatted_address: "warehouse" },
          },
          dropOffAddress: {
            address: dropOffType[0]
              ? dropOffAddressData
              : { formatted_address: "warehouse" },
          },
          status: "Order Placed",
          pay_status: "Unpaid",
        },
        localStorage.getItem("token")
          ? "https://admin.loadpool.co.za/api/v1/order/placeOrderAuth"
          : "https://admin.loadpool.co.za/api/v1/order/placeOrder"

        // "/api/v1/order/placeOrderAuth"
      );
      return res;
    }
  };

  handleReset = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      ...this.state,
      dataEntry: ["", "", "", ""],
      deliveryType: [false, false],
      pickupType: [false, false],
      dropOffType: [false, false],
      pickupAddress: "",
      pickupAddressData: {},
      dropOffAddress: "",
      dropOffAddressData: {},
      isInsured: [false, false],
      valueOfGoods: "",
      tnc: false,
      selectSize: ["", "", "", ""],
      illegal: false,
      sliderValue: [0, 0, 0, 0],
      personalDetails: {
        name: "",
        email: "",
        phoneNo: "",
        address: "",
        message: "",
      },
      error: {
        from: "",
        to: "",
        dataEntry: "",
        dataEntry2: "",
        deliveryType: ["", ""],
        pickupType: ["", ""],
        dropOffType: ["", ""],
        pickupAddress: "",
        dropOffAddress: "",
        tnc: "",
        illegal: "",
        isInsured: ["", ""],
        valueOfGoods: "",
        sliderValue: ["", "", "", ""],
        personalDetails: {
          name: "",
          email: "",
          phoneNo: "",
          address: "",
          message: "",
        },
      },
    });
  };

  resetErrors = () => {
    this.setState({
      error: {
        from: "",
        to: "",
        dataEntry: "",
        dataEntry2: "",
        deliveryType: ["", ""],
        pickupType: ["", ""],
        dropOffType: ["", ""],
        pickupAddress: "",
        dropOffAddress: "",
        tnc: "",
        illegal: "",
        isInsured: ["", ""],
        valueOfGoods: "",
        sliderValue: ["", "", "", ""],
        personalDetails: {
          name: "",
          email: "",
          phoneNo: "",
          address: "",
          message: "",
        },
      },
    });
  };

  getTotal = () => {
    let total = 0;
    if (this.state.dataEntry) {
      this.state.dataEntry.forEach((ele) => {
        if (ele) {
          total += Number(ele.number) * Number(ele.price);
        }
      });
    }
    if (this.state.pickupType[0]) {
      total += 500;
    }
    if (this.state.dropOffType[0]) {
      total += 500;
    }
    if (this.state.deliveryType[0]) {
      total += 500;
    }
    if (this.state.isInsured[0]) {
      if (this.state.valueOfGoods) {
        total += (Number(this.state.valueOfGoods) * 3) / 100;
      }
    }
    return total;
  };

  Cities = () => {
    if (this.state.cities[0]) {
      let temp = ["From", ...this.state.cities].map((item, i) => {
        if (i !== 0) {
          if (i == this.state.to) {
            //item = "";
          }
        }
        return item;
      });

      let temp2 = ["To", ...this.state.cities].map((item, i) => {
        if (i !== 0) {
          if (i == this.state.from) {
            item = "";
          }
        }
        return item;
      });
      return (
        <Mainbox
          title="Where would you like to send your goods?"
          optionone={
            <Dropdown
              id="ddID1"
              check={{ text: this.state.error.from }}
              setValue={this.setFrom}
              value={this.state.from}
              options={temp}
            />
          }
          optiontwo={
            <Dropdown
              id="ddID2"
              check={{ text: this.state.error.to }}
              setValue={this.setTo}
              value={this.state.to}
              options={temp2}
            />
          }
          showInputBox="none"
        />
      );
    } else {
      return (
        <Spinner
          style={{ marginBottom: 27 }}
          animation="border"
          variant="primary"
        />
      );
    }
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("token")) {
      this.setState({ isLoggedIn: false });
      localStorage.setItem("token", "");
    }
    let res = await axios.get(
      `https://admin.loadpool.co.za/api/v1/user/getProfile?token=${localStorage.getItem(
        "token"
      )}`
    );
    if (res.data.data) {
      let { name, email, phoneNo, addresses } = res.data.data;
      // let { address } = addresses.slice(-1)[0];
      this.setState({
        personalDetails: {
          ...this.state.personalDetails,
          name,
          email,
          phoneNo,
          addresses,
        },
      });
    }

    let res2 = await axios.get(
      `https://admin.loadpool.co.za/api/v1/city/get`
    );
    if (res2.data.city[0]) {
      let cities = res2.data.city;
      let newCities = cities.map((city) => city.toUpperCase());
      this.setState({
        cities: newCities,
      });
    }
  };


  // render

  render() {

    let total = 0;
    //-----------
    if(this.state.isInsured[1])
    {
    	this.state.valueOfGoods="";
    }
    //---------
    if (this.state.dataEntry) {
      this.state.dataEntry.forEach((ele) => {
        if (ele) {
          total += Number(ele.number) * Number(ele.price);
        }
      });
    }
    if (this.state.pickupType[0]) {
      total += 500;
    }
    if (this.state.dropOffType[0]) {
      total += 500;
    }
    if (this.state.deliveryType[0]) {
      total += 500;
    }
    if (this.state.isInsured[0]) {
      if (this.state.valueOfGoods) {
        total += (Number(this.state.valueOfGoods) * 3) / 100;
      }
    }
    //---------
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          // onClose={() => {
          //   window.location.url("/");
          // }}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.thankyou ? (
          <>
            <Ty isloggedin={this.state.isLoggedIn} />
          </>
        ) : (
          <>
            <header className={bookingform.realHeader}>
              {/* <MainHeader /> */}
              <Navbar booking={true} />
            </header>
            <Booknownav />
            <div className={bookingform.main}>
              <div className={bookingform.order}>
                <Container>
                  <Form action="https://www.payfast.co.za/eng/process" method="post" id="myForm">
                    <input type="hidden" name="merchant_id" value="17778925" />
   					        <input type="hidden" name="merchant_key" value="ybbjle24a2s5a" />
   					        <input type="hidden" name="return_url" value="https://loadpool.co.za/payment-success" />
					          <input type="hidden" name="cancel_url" value="https://loadpool.co.za/payment-cancel" />
					          <input type="hidden" name="notify_url" value="https://admin.loadpool.co.za/api/v1/order/notifyOrder" />
   					        <input type="hidden" name="email_address" value={this.state.personalDetails.email} />
             		    <input type="hidden" name="m_payment_id" value="0" id="orderID" />
   					        <input type="hidden" name="amount" value={total} id="Amount"/>
   					        <input type="hidden" name="item_name" value={selProducts(this.state.dataEntry)} />
   					        <input type="hidden" name="passphrase" value="Goodearthwebsites22" />
   					        <input type="hidden" name="signature" id="signature" value={generateSignature({merchant_id:"17778925", merchant_key:"ybbjle24a2s5a", return_url:"https://loadpool.co.za/payment-success", cancel_url:"https://loadpool.co.za/payment-cancel", notify_url:"https://admin.loadpool.co.za/api/v1/order/notifyOrder", email_address:this.state.personalDetails.email, m_payment_id:0, amount:total, item_name:selProducts(this.state.dataEntry), passphrase:"Goodearthwebsites22"})} />
   				  </Form>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col lg="8" className={bookingform.colOne}>
                        <div className={bookingform.theStickySidebar}>
                          {this.Cities()}
                          {/* -------------------------111----------- */}

                          {/* -----------------------222---------------------------- */}
                          <Mainbox
                            title="What size is the goods you want to send?"
                            subHeading="FOR LARGER SIZE GOODS, PLEASE SUBMIT A QUERY "
                            showInputBox="none"
                            isLink={true}
                            md={12}
                            // CMHandler={this.productPush}
                            optionone={
                              // <Dropdown
                              //   id="ddID3"
                              //   ObjectDropDown={true}
                              //   setValue={this.setSelectSize}
                              //   check={{ text: this.state.error.selectSize }}
                              //   value={this.state.selectSize}
                              //   options={sizeOptions}
                              // />
                              <BookingFormSelect
                                productPush={this.productPush}
                                setValue={this.setSelectSize}
                                check={{ text: this.state.error.dataEntry }}
                                counterCheck={this.state.error.sliderValue}
                                value={this.state.selectSize}
                                counterVal={this.state.sliderValue}
                                setCounterVal={this.setSliderValue}
                                options={this.state.products}
                              />
                            }
                            // optiontwo={
                            //   <Slidernew
                            //     value={this.state.sliderValue}
                            //     setValue={this.setSliderValue}
                            //   />
                            // }
                          />
                          {/* --------------------------33333-------------------- */}

                          {/* ----------------------444444-------------------- */}
                          <Mainbox
                            md={"12"}
                            title="Would you like us to collect your goods?"
                            optionone={
                              <Checkbox
                                inputId="idone"
                                check={{
                                  text: this.state.error.pickupType[0],
                                }}
                                value={this.state.pickupType}
                                setValue={this.setPickupType}
                                index={"0"}
                                label="I want Loadpool to pick up my goods (R500)"
                              />
                            }
                            optiontwo={
                              <Checkbox
                                check={{
                                  text: this.state.error.pickupType[1],
                                }}
                                value={this.state.pickupType}
                                setValue={this.setPickupType}
                                index={"1"}
                                inputId="idoneTWO"
                                label="I will deliver my goods to the Loadpool depot"
                              />
                            }
                            city={
                              this.state.from === 0 ? ""
                                : this.state.cities[this.state.from - 1]
                            }
                            addressData={this.state.pickupAddressData}
                            setAddressData={this.setPickupAddressData}
                            addresses={this.state.personalDetails.addresses}
                            check={{ text: this.state.error.pickupAddress }}
                            inputValue={this.state.pickupAddress}
                            setInputValue={this.setPickupAddress}
                            showInputBox="block"
                            inputLabel="Enter Your Address"
                            disabled={!this.state.pickupType[0]}
                            disabledw={!this.state.pickupType[1]}
                            googleMapLatLng={new google.maps.LatLng(-29.930900, 30.972430)}
                          />
                          <Mainbox
                            md={12}
                            title="Would you like us to deliver your goods?"
                            optionone={
                              <Checkbox
                                inputId="i2done"
                                check={{
                                  text: this.state.error.dropOffType[0],
                                }}
                                value={this.state.dropOffType}
                                setValue={this.setDropOffType}
                                index={"0"}
                                label="I want Loadpool to drop off my goods (R500)"
                              />
                            }
                            optiontwo={
                              <Checkbox
                                check={{
                                  text: this.state.error.dropOffType[1],
                                }}
                                value={this.state.dropOffType}
                                setValue={this.setDropOffType}
                                index={"1"}
                                inputId="i3doneTWO"
                                label="I will collect my goods from the Loadpool depot"
                              />
                            }
                            city={
                              this.state.to === 0
                                ? ""
                                : this.state.cities[this.state.to - 1]
                            }
                            check={{ text: this.state.error.dropOffAddress }}
                            addressData={this.state.dropOffAddressData}
                            setAddressData={this.setDropOffAddressData}
                            addresses={this.state.personalDetails.addresses}
                            inputValue={this.state.dropOffAddress}
                            setInputValue={this.setDropOffAddress}
                            showInputBox="block"
                            inputLabel="Enter Your Address"
                            disabled={!this.state.dropOffType[0]}
                            disabledw={!this.state.dropOffType[1]}
                            googleMapLatLng={new google.maps.LatLng(-26.119900, 28.358000)}
                          />
                          <Mainbox
                            title="What type of delivery would you like?"
                            // subHeading="For your peace of mind, you have the option of taking insurance on the goods you’re sending. Insurance cover is calculated at 3% of the total value that you stipulate."
                            optionone={
                              <Checkbox
                                check={{
                                  text: this.state.error.deliveryType[0],
                                }}
                                inputId="idtwo2"
                                index={"0"}
                                value={this.state.deliveryType}
                                setValue={this.setDeliveryType}
                                label="Express Delivery - 2 days (R500 Extra)"
                              />
                            }
                            optiontwo={
                              <Checkbox
                                index={"1"}
                                check={{
                                  text: this.state.error.deliveryType[1],
                                }}
                                value={this.state.deliveryType}
                                setValue={this.setDeliveryType}
                                inputId="idonetwotwo2"
                                label="Standard Delivery - 5 days"
                              />
                            }
                          />
                          <Mainbox
                          	md={12}
                            title="Would you like your goods to be insured?"
                            subHeading="For your peace of mind, you have the option of taking insurance on the goods you’re sending. Insurance cover is calculated at 3% of the total value that you stipulate."
                            isInsurance="true"
                            optionone={
                              <Checkbox
                                check={{
                                  text: this.state.error.isInsured[0],
                                }}
                                value={this.state.isInsured}
                                setValue={this.setIsInsured}
                                index={"0"}
                                inputId="idtwo"
                                label="YES"
                              />
                            }
                            optiontwo={
                              <Checkbox
                                check={{
                                  text: this.state.error.isInsured[0],
                                }}
                                inputId="idonetwotwo"
                                value={this.state.isInsured}
                                setValue={this.setIsInsured}
                                index={"1"}
                                label="NO"
                              />
                            }
                            check={{ text: this.state.error.valueOfGoods }}
                            inputValue={this.state.valueOfGoods}
                            setInputValue={this.setValueOfGoods}
                            showInputBox="block"
                            inputLabel="Enter Value of Goods"
                            disabled={!this.state.isInsured[0]}
                          />

                        </div>
                        {!this.state.isLoggedIn && (
                          <Inputform
                            check={{
                              checkName: {
                                text: this.state.error.personalDetails.name,
                              },
                              checkEmail: {
                                text: this.state.error.personalDetails.email,
                              },
                              checkPhoneNo: {
                                text: this.state.error.personalDetails.phoneNo,
                              },
                              checkMessage: {
                                text: this.state.error.personalDetails.message,
                              },
                            }}
                            value={this.state.personalDetails}
                            setValue={this.setPersonalDetails()}
                          />
                        )}
                      </Col>

                      <Col lg="4">
                        <Ordersummary
                          check={{
                            checkTotal: {
                              text: this.state.error.dataEntry,
                              textErr: this.state.error.dataEntry2
                            },
                            checkTnc: {
                              text: this.state.error.tnc,
                            },
                            checkIllegal: {
                              text: this.state.error.illegal,
                            },
                          }}
                          total={total}
                          data={this.state.dataEntry}
                          tnc={this.state.tnc}
                          isInsured={this.state.isInsured[0]}
                          pickupType={this.state.pickupType[0]}
                          dropOffType={this.state.dropOffType[0]}
                          deliveryType={this.state.deliveryType[0]}
                          valueOfGoods={this.state.valueOfGoods}
                          illegal={this.state.illegal}
                          svgHandler={this.productDrop}
                          setTnc={this.setTnc}
                          setIllegal={this.setIllegal}
                          resetBtn={this.handleReset}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </div>
            </div>
          </>
        )}
        {/* ---------------mainheader---------------- */}
      </>
    );
  }
}

export default Bookingformpage;
