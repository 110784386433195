import React, { Component } from "react";
import { Row } from "react-bootstrap";
import styles from "./validationbox.module.scss";

class Validationbox extends Component {
  render() {
    return (
      <div
        style={
          this.props.style
            ? { ...this.props.style, margin: "10px 0px" }
            : { margin: "10px 0px" }
        }
      >
        <div className={styles.arrowUp}></div>
        <p className={styles.validationBox}>{this.props.text} </p>
      </div>
    );
  }
}

export default Validationbox;
