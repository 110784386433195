import React, { Component } from "react";
import Checkstyle from "./Checkbox.module.scss";
import Modal from "../modal/modal";
import Validationbox from "../validationbox/validationbox";

class Checkbox extends Component {
  state = { showModal: false, value: false };
  showHideModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  checkSet = () => {
    let { check } = this.props;
    if (!check) check = {};
    check.style = { display: "none" };
    return null;
  };

  render() {
    let {
      setValue,
      index,
      value,
      inputId,
      padding,
      border,
      label,
      height,
      textWidth,
      align,
      check,
    } = this.props;
    if (!(index === "0" || index === "1")) index = false;
    return (
      <div className={Checkstyle.Checkbox}>
        <div className={Checkstyle.labelAndCheckbox}>
          <input
            type="hidden"
            name="extraOption1Title"
            value="Extra Option 1"
          />
          <input
            type="checkbox"
            id={inputId}
            className={Checkstyle.inpCbx}
            name="extraOption1"
            value="50.00"
            checked={typeof value == "object" ? value[Number(index)] : value}
            onChange={(e) => {
              setValue(e.target.checked ? true : false, index);
              this.setState({
                showModal: e.target.checked ? true : false,
              });
              
            }}
          />
          <label
            className={Checkstyle.cbx}
            style={{
              ...this.props.style,
              border: border ? border : "auto",
              padding: padding ? padding : "auto",
              height: height ? "auto" : "auto",
            }}
            htmlFor={inputId}
          >
            <span style={{ verticalAlign: align ? align : "center" }}>
              <svg width="12px" height="10px" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </svg>
            </span>
            {label && (
              <span style={{ width: textWidth ? textWidth : "center" }}>
                {label}
              </span>
            )}

            {label === "Submit at Warehouse" && (
              <div>
                {this.state.showModal && (
                  <div className={Checkstyle.modal}>
                    <Modal
                      title="19 Jay Road Fairleads, Benoni, Johannesburg."
                      heading="Please deliver your goods to the address of our Johannesburg depot indicated Above."
                    />
                  </div>
                )}
              </div>
            )}
          </label>
        </div>
        <Validationbox
          text={
            check
              ? check.text
                ? check.text
                : this.checkSet()
              : "Order is empty."
          }
          style={check ? (check.style ? check.style : {}) : {}}
        />
      </div>
    );
  }
}

export default Checkbox;
