import React, { Component } from "react";

//grid import
import { Container, Row } from "react-bootstrap";

//scss import
import TCstyle from "./TnC.module.scss";

//component import
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
export class TnC extends Component {
  render() {
    return (
      <>
          <header className={TCstyle.realHeader}>
            <MainHeader />
            <Navbar />
          </header>
          <Row className={TCstyle.img}>
            <AboutImg pageName="Privacy Policy" />
          </Row>
          <Row className={TCstyle.heading}>
            <h2>Ezio Privacy Policy</h2>
            <p>This policy was last updated {this.props.date}.</p>
          </Row>
          <Row className={TCstyle.policiesSec}>
            <h4>Information Collection </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea com modo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore fugiat. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut abore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip
            </p>
            <ol type="1">
              <li>
                <h4>1. General Information</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut abore et dolore magna aliqua.
                  <br />
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore fugiat. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut abore
                  etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip Lorem ipsum dolor
                  <br />
                  sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut abore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea com modo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore fugiat.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut abore et dolore magna aliqua.
                  <br />
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting.
                  </li>
                  <li>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting.
                  </li>
                  <li>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting.
                  </li>
                  <li>
                    It has survived not only five centuries, but also the leap
                    into electronic typesetting.
                  </li>
                </ul>
              </li>
              <li>
                <h4>2. General Information</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut abore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore fugiat.
                </p>
              </li>
              <li>
                <h4>3. General Information</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut abore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore fugiat.
                </p>
              </li>
              <li>
                <h4>4. General Information</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut abore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore fugiat.
                </p>
              </li>
            </ol>
          </Row>
          <Footer />

      </>
    );
  }
}

export default TnC;
