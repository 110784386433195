import React, { Component } from "react";
// ----------modulesass----------------------------
import whychooseus from "./whychooseus.module.scss";
// --------------------bootstrap----------------------
import { Container, Row, Col } from "react-bootstrap";
// ----------------component-----------------
import Singlechooseus from "../singlechooseus/singlechooseus";
// -------------------boxicons import-------------
import "boxicons";
// -----------------owlcarousel--------------
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Whychooseus extends Component {
  render() {
    return (
      <div>
        <Container className={whychooseus.container}>
          <Row className={whychooseus.row}>
            <Col lg="6">
              <div className={whychooseus.aboutContent}>
                {/* <Row>
                  <span className={whychooseus.topTitle}>Why Choose Us</span>
                </Row> */}
                <Row>
                  <h2>
                    Tired of being asked Length, Breadth, Height & Weight for
                    your goods?
                  </h2>
                </Row>
                <Row>
                  <p>
                    Having a national footprint ourselves, we’ve often sought
                    the services of Courier Company’s only to be confronted with
                    the daunting interrogation of L, B, W & H of our goods.
                    Seriously, we're certain that none of us readily have this
                    information at hand? More often than not, when one
                    eventually receives the quote, it's usually higher than the
                    value of the goods being transported. Loadpool has removed
                    the frustration of L, B, W & H of goods via the use of
                    illustrated images.
                  </p>
                </Row>
              </div>
              <Row>
                <OwlCarousel
                  items={3}
                  autoplayTimeout={4000}
                  smartSpeed={1000}
                  className={`owl-theme single-test-main`}
                  fluidSpeed
                  autoplay
                  autoplayHoverPause
                  responsiveClass
                  loop
                  margin={0}
                >
                  <Col>
                    <Singlechooseus
                      icon={
                        <box-icon name="calendar" size="40px" color="#0b8dd4" />
                      }
                      color="#fff7ea"
                      desc="5+ years work experience"
                    />
                  </Col>
                  <Col>
                    <Singlechooseus
                      icon={
                        <box-icon name="calendar" size="40px" color="#0b8dd4" />
                      }
                      desc="Businesses of all sizes"
                      color="#fff7ea"
                    />
                  </Col>
                  <Col>
                    <Singlechooseus
                      icon={
                        <box-icon
                          name="user"
                          size="40px"
                          color="#0b8dd4"
                        ></box-icon>
                      }
                      desc="Last minute service transportation"
                      color="#eef9ff"
                    />
                  </Col>
                  <Col>
                    <Singlechooseus
                      icon={
                        <box-icon
                          name="globe"
                          size="40px"
                          color="#0b8dd4"
                        ></box-icon>
                      }
                      color="#fff1f1"
                      desc="Support activities for transportation"
                    />
                  </Col>
                </OwlCarousel>
              </Row>
            </Col>
            <Col lg="6" style={{ marginTop: "1.2rem" }}>
              <div className={whychooseus.chooseusImg}></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Whychooseus;
