import React, { Component } from "react";
import axios from "axios";
import inputbox from "./inputbox.module.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Form, FloatingLabel } from "react-bootstrap";
import Validationbox from "../validationbox/validationbox";

class Inputbox extends Component {

  checkSet = () => {
    let { check } = this.props;
    if (!check) check = {};
    check.style = { display: "none" };
    return null;
  };

  state = {
    address: "",
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
  };

  onClick = async (e) => {
    if (e.currentTarget.innerText) {
      console.log(e.currentTarget.innerText);
      //this.props.onChange(e.currentTarget.innerText);
      //this.props.setAddressData(e.currentTarget.innerText);
      // const results = await geocodeByAddress(e.currentTarget.innerText);
      // this.props.setAddressData(e.currentTarget.innerText);
      // if (this.props.setAddressData) {
      //   this.props.setAddressData(e.currentTarget.innerText);
      // }
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
      });
    }
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      e.preventDefault();

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      e.preventDefault();

      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      e.preventDefault();

      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    let {
      type,
      disabled,
      borderColor,
      onChange,
      label,
      controlId,
      inputValue,
      check,
      setAddressData,
      suggestions,
      googleMapLatLng,
      googleMapLatLngv1,
      googleMapLatv1,
      googleMapLngv1,
      depotAddress,
    } = this.props;
    if (!check) check = {};
    const {
      onKeyDown,
      onClick,
      state: { activeSuggestion, filteredSuggestions, showSuggestions },
    } = this;

    let suggestionsListComponent;
    if (suggestions) {
      if (showSuggestions && inputValue) {
        if (filteredSuggestions.length) {

          suggestionsListComponent = (
            <ul className={inputbox.suggestions}>
              {filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = inputbox.suggestionActive;
                }

                return (
                  <li className={className} key={index} onClick={onClick}>
                    {suggestion.description}
                  </li>
                );
              })}
            </ul>
          );
        } else {
          if (inputValue) {

            const searchOptions = {location: googleMapLatLng, radius: 40000, types: ['address']}
            return (
              <PlacesAutocomplete
                value={inputValue}
                searchOptions={searchOptions}
                onChange={async (address) => {
                  if (onChange) {
                    const userInput = onChange(address);
                  }
                  //this.setState({ address });
                }}
                onSelect={async (value) => {
                  const results = await geocodeByAddress(value);
                  if (onChange) {
                    const userInput = onChange(value);
                  }
                  //------------------
                  const latLng = await getLatLng(results[0]);
                  //console.log(value);
                  //console.log(latLng.lat);
                  //console.log(latLng.lng);
                  ////'https://maps.googleapis.com/maps/api/distancematrix/json?origins='+googleMapLatLngv1+'&destinations='+latLng.lat+'%2C'+latLng.lng+'&key=AIzaSyByIp-8EjGNA8ydsc5iuJ-90IWiaIF7p84'
                  var distance = 0;
                  if(latLng.lat != undefined && latLng.lng != undefined)
                  {
                      var destinationsLatLng = latLng.lat+','+latLng.lng;
                      var destinationsLat = latLng.lat;
                      var destinationsLng = latLng.lng;
                      var config = {
                        method: 'post',
                        headers: {
                          "content-type": "application/json; charset=utf-8",
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                        },
                        url: 'https://admin.loadpool.co.za/api/v1/user/calculate-distances',
                        data: { googleMapLatv1, googleMapLngv1, destinationsLat, destinationsLng},
                      };
                      await axios(config).then(function (response) {
                        console.log(response.data.msg);
                        //distance = response.data.msg.replace(/[^\d.-]/g, '');
                        distance = response.data.msg;
                      }).catch(function (error) {
                        console.log(error);
                      });
                  }
                  if(parseInt(distance) > 0 && parseInt(distance) <= 40)
                  {
                    this.props.onChange(value);
                    this.props.setAddressData(value);
                    if (setAddressData) {
                      setAddressData(value);
                    }
                  }
                  else
                  {
                    this.props.onChange('');
                    this.props.setAddressData('');
                    toast.error("Please note that your distance must be within 40km of our depot");
                    //toast.error("Distance should be less than or equal to 40 KM between " + value + " & " + depotAddress);
                  }

                }}
                onKeyDown={onKeyDown}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <FloatingLabel
                      controlId={(controlId !== null || controlId !== undefined) ? controlId : "floatingInput"}
                      label={label}
                      style={{ marginTop: "38px !important" }}
                      className={
                        borderColor === "#0383ff"
                          ? `${inputbox.abc} ${inputbox.mb3}`
                          : inputbox.mb3
                      }
                    >
                      <Form.Control
                        {...getInputProps({
                          placeholder: "name@example.com",
                          className:
                            borderColor === "#0383ff"
                              ? `${inputbox.abc} ${inputbox.formcontrolismyclass}`
                              : inputbox.formcontrolismyclass,
                          disabled: disabled,
                          type: type,
                          autoFocus: true,
                        })}
                      />
                      {loading}
                      <ul className={inputbox.suggestions}>
                        {suggestions.map((suggestion, index) => {
                          let className;

                          // Flag the active suggestion with a class
                          if (index === activeSuggestion) {
                            className = inputbox.suggestionActive;
                          }
                          let element;

                          suggestion.terms.forEach((ele) => {
                            if (false && this.props.city) {     // restricting the addresses with city names selected.
                              if (
                                this.props.city.toLowerCase() ==
                                ele.value.toLowerCase()
                              ) {
                                element = (
                                  <li
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      key: index,
                                      onClick: onClick,
                                    })}
                                  >
                                    {suggestion.description}
                                  </li>
                                );
                              }
                            }
                            else
                            {
                            	element = (
                                  <li
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      key: index,
                                      onClick: onClick,
                                    })}
                                  >
                                    {suggestion.description}
                                  </li>
                                );
                            }
                          });
                          return element;
                        })}
                      </ul>
                    </FloatingLabel>
                    <Validationbox
                      text={
                        check
                          ? check.text
                            ? check.text
                            : this.checkSet()
                          : this.checkSet()
                      }
                      style={check ? (check.style ? check.style : {}) : {}}
                    />
                  </div>
                )}
              </PlacesAutocomplete>
            );
          }
        }
      }
    }
    if (this.props.suggestions) {
      return (
        <div>
          <FloatingLabel
            controlId={(controlId !== null || controlId !== undefined) ? controlId : "floatingInput"}
            label={label}
            className={
              borderColor === "#0383ff"
                ? `${inputbox.abc} ${inputbox.mb3}`
                : inputbox.mb3
            }
          >
            <Form.Control
              className={
                borderColor === "#0383ff"
                  ? `${inputbox.abc} ${inputbox.formcontrolismyclass}`
                  : inputbox.formcontrolismyclass
              }
              value={inputValue}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                if (onChange) {
                  const userInput = onChange(e.target.value);
                  const { suggestions } = this.props;
                  if (suggestions) {
                    // Filter our suggestions that don't contain the user's input
                    const filteredSuggestions = suggestions.filter(
                      (suggestion) =>
                        suggestion.type
                          .toLowerCase()
                          .indexOf(userInput.toLowerCase()) > -1 ||
                        suggestion.address.formatted_address
                          .toLowerCase()
                          .indexOf(userInput.toLowerCase()) > -1
                    );

                    this.setState({
                      activeSuggestion: 0,
                      filteredSuggestions,
                      showSuggestions: true,
                      userInput: e.currentTarget.value,
                    });
                  }
                }
              }}
              disabled={disabled}
              type={type}
              placeholder="name@example.com"
            />
            {suggestionsListComponent}
          </FloatingLabel>
          <Validationbox
            text={
              check
                ? check.text
                  ? check.text
                  : this.checkSet()
                : this.checkSet()
            }
            style={check ? (check.style ? check.style : {}) : {}}
          />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <FloatingLabel
              controlId={(controlId !== null || controlId !== undefined) ? controlId : "floatingInput"}
              label={label}
              className={
                borderColor === "#0383ff"
                  ? `${inputbox.abc} ${inputbox.mb3}`
                  : inputbox.mb3
              }
            >
              <Form.Control
                className={
                  borderColor === "#0383ff"
                    ? `${inputbox.abc} ${inputbox.formcontrolismyclass}`
                    : inputbox.formcontrolismyclass
                }
                value={inputValue}
                onChange={(e) => {
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
                disabled={disabled}
                type={type}
                placeholder="name@example.com"
              />
            </FloatingLabel>
            <Validationbox
              text={
                check
                  ? check.text
                    ? check.text
                    : this.checkSet()
                  : this.checkSet()
              }
              style={check ? (check.style ? check.style : {}) : {}}
            />
          </div>
        </>
      );
    }
  }
}

export default Inputbox;
