import React, { Component } from "react";
import CommonButton from "../../exioComponents/commonButton/CommonButton";
import { Row, Col } from "react-bootstrap";
import { Parallax } from "react-parallax";
import image1 from "../../../resources/LoadpoolImages/shipment-bg.jpg";
import shipment from "./shipmentarea.module.scss";

class Shipment extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Parallax bgImage={image1} strength={150} style={{}}>
          <div style={{ height: 500 }}>
            <div className={shipment.parallax}>
              <span className={shipment.topTitle}>Shipment</span>
              <h2>Are You A Shipper? Please Knock Us On The Below Button</h2>
              <p className={shipment.description}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                iste facere, unde nesciunt ratione amet voluptatibus aut, iure
                magnam accusantium consequatur voluptatum nam ipsum in soluta
                consectetur temporibus nobis itaque!
              </p>
              <Row className={shipment.btnRow}>
                <Col
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 6 }}
                >
                  <CommonButton
                    to="/booknow"
                    type="button"
                    btnText="Make a Shipment"
                    scroll={true}
                  />
                </Col>
                <Col className={shipment.btn2}
                  xl={{ span: 6 }}
                  lg={{ span: 6 }}
                  md={{ span: 6 }}
                  sm={{ span: 6 }}
                >
                  <CommonButton
                    to="/contactus"
                    type="button"
                    btnText="Contact Us"
                    scroll={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Parallax>
      </div>
    );
  }
}

export default Shipment;
