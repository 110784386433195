import React, { Component } from "react";
import ddstyle from "./DDown.scss";
import $ from "jquery";
window.jQuery = window.$ = $;
require("jquery-nice-select");

class DDown extends Component {
  optionList = () => {
    if (this.props.optionList) {
      return this.props.optionList.map((element, i, arr) => {
        return <option value="0">{element}</option>;
      });
    }
  };
  myRef = React.createRef();
  componentDidMount() {
    $(this.myRef.current).niceSelect();
  }
  render() {
    return (
      <>
        <input
          type="hidden"
          id="option1Title"
          name="option1Title"
          value="Select"
        />
        <input type="hidden" id="option1Price" name="option1Price" value="0" />
        <select
          id="optionGroup1List"
          className="wide price-list"
          name="optionGroup1List"
          ref={this.myRef}
        >
          {this.optionList()}
        </select>
      </>
    );
  }
}
export default DDown;
