import React, { Component } from "react";
import axios from "axios";
import registerpage from "./registerpage.module.scss";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import Register from "../../UpdatedComponents/registerform/register";
import { Navigate } from "react-router-dom";
class Registerationpage extends Component {
  state = { isloggedin: false };

  componentDidMount = async () => {
    let res = await axios.get(
      "https://admin.loadpool.co.za/api/v1/user/getProfile"
    );
    if (res.data.statusCode === 200) {
      this.setState({ isloggedin: true });
    }
  };
  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={registerpage.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image------------------ */}
        {/* <AboutImg pageName="Credit Registration For Corporates" /> */}
        {/* ---------------LogIn---------------- */}
        <div className={registerpage.loginform}>
          <Register />
        </div>
        {/* <Footer /> */}
        {this.state.isloggedin && <Navigate to="/" />}
      </>
    );
  }
}

export default Registerationpage;
