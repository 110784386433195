import React, { Component } from "react";
import CommonDDstyle from "./CommonDD.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export class CommonDD extends Component {
  loadList = () => {
    if (this.props.list) {
      return this.props.list.map((ele, i) => {
        if (ele.isName) {
          return (
            <li key={i} className="CommonDD_menuItem__rnGrTs" style={{ padding: "10px", cursor: "initial", color:"#000", background: "#DDD", textAlign: "center", borderBottom: "0"  }}>
              
                {ele.title}
              
            </li>
          );
        }
        else if (!ele.subHead) {
          return (
            <li key={i} className={CommonDDstyle.menuItem}>
              <Link
                state={{ from: ele.from }}
                to={ele.to}
                onClick={
                  ele.onClick
                    ? ele.onClick
                    : () => {
                        window.scrollTo(0, 0);
                      }
                }
                style={{ textDecoration: "none" }}
              >
                {ele.title}
              </Link>
            </li>
          );
        } else if (ele.subHead) {
          let subMenu = () => {
            return ele.subHead.map((ele, i, arr) => {
              return (
                <li key={i} className={CommonDDstyle.subMenuItem}>
                  <Link
                    // state={{ from: this.props.from }}
                    className={CommonDDstyle.subMenuAnchor}
                    to={ele.to}
                    style={{ textDecoration: "none" }}
                    onClick={
                      ele.onClick
                        ? ele.onClick()
                        : () => {
                            window.scrollTo(0, 0);
                          }
                    }
                  >
                    {ele.title}
                  </Link>
                </li>
              );
            });
          };

          return (
            <li key={i} className={CommonDDstyle.menuItem}>
              {ele.title}
              <FontAwesomeIcon
                icon={faChevronRight}
                className={CommonDDstyle.angleRight}
              />
              <ul className={CommonDDstyle.subMenu}>{subMenu()}</ul>
            </li>
          );
        }
      });
    }
  };

  render() {
    return (
      <Nav.Item as="li" className={CommonDDstyle.MenuParent} tab-index="0">
        <Nav.Link
          as={Link}
          state={{ from: 0 }}
          to={this.props.to ? this.props.to : "#"}
          className={CommonDDstyle.mainLinks}
        >
          {this.props.name}
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{
              display: this.props.list ? "auto" : "none",
              boxShadow: this.props.list ? "auto" : "none",
            }}
            className={CommonDDstyle.angle}
          />
        </Nav.Link>

        <ul className={CommonDDstyle.Menu}>{this.loadList()}</ul>
      </Nav.Item>
    );
  }
}

export default CommonDD;
