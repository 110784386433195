import React, { Component } from "react";
import chooseUs from "./singlechooseus.module.scss";

class Singlechooseus extends Component {
  render() {
    return (
      <>
        <div
          className={chooseUs.singleChooseUsBox}
          style={{ backgroundColor: this.props.color }}
        >
          <div className={chooseUs.icon}>{this.props.icon}</div>

          {/* <Calendar className={chooseUs.icon}></Calendar> */}
          <p className={chooseUs.singleChooseUsline}>{this.props.desc}</p>
        </div>
      </>
    );
  }
}

export default Singlechooseus;
