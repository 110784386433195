import React, { Component } from "react";
//scss style
import CounterStyle from "./Counter.module.scss";
import { Transition } from "react-transition-group";
import styled from "styled-components";

//icons
import "boxicons";

const Ul = styled.ul`
  transition: 0.2s ease;
  /* Hidden init state */
  opacity: 0;
  transform: translateY(-10px);
  &.enter,
  &.entered {
    /* Animate in state */
    opacity: 1;
    transform: translateY(0px);
  }
  &.exit,
  &.exited {
    /* Animate out state */
    opacity: 0;
    transform: translateY(-10px);
  }
`;

export class Counter extends Component {
  add = (e) => {
    e.preventDefault();

    this.props.setValue(this.props.value + 1, this.props.index);
  };
  minus = (e) => {
    e.preventDefault();
    if (this.props.value > 0) {
      this.props.setValue(this.props.value - 1, this.props.index);
    }
  };
  render() {
    return (
      <>
        <Transition
          mountOnEnter
          unmountOnExit
          timeout={200}
          in={this.props.show}
        >
          {(state) => {
            let className1 = state;
            return (
              <Ul
                className={`${CounterStyle.counter} ${className1}`}
                style={{ ...this.props.style }}
              >
                {this.props.text ? (
                  <li>{this.props.text}</li>
                ) : (
                  <li style={{ margin: "0" }}></li>
                )}
                <li>
                  <button onClick={this.minus}>
                    <box-icon color="#000024" name="minus"></box-icon>
                  </button>
                </li>
                <li>
                  <input
                    type="text"
                    onChange={(e) => {
                      e.preventDefault();
                      let value = e.target.value;
                      if (Number(value) || value === "") {
                        this.props.setValue(Number(value), this.props.index);
                      }
                    }}
                    value={this.props.value}
                  />
                </li>
                <li>
                  <button onClick={this.add}>
                    <box-icon color="#000024" name="plus"></box-icon>
                  </button>
                </li>
              </Ul>
            );
          }}
        </Transition>
      </>
    );
  }
}

export default Counter;
