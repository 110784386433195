import React, { Component } from "react";
import { Col } from "react-bootstrap";
import CommonButton from "../../UpdatedComponents/exioComponents/commonButton/CommonButton";
import error from "./error.module.scss";

class Error extends Component {
  render() {
    return (
      <div>
        <div className={error.errorArea}>
          <div className={error.dTable}>
            <div className={error.dTableCell}>
              <div className={error.errorContent}>
                <h1 className={error.errorhead1}>
                  <span className={error.A}>4</span>{" "}
                  <span className={error.red}>0</span>{" "}
                  <span className={error.B}>4</span>{" "}
                </h1>
                <h3 className={error.errorhead3}>Oops! Page Not Found</h3>
                <p className={error.errorPara}>
                  The page you were looking for could not be found.
                </p>
                <Col lg={{ span: 2, offset: 6 }} style={{ marginLeft: "42%" }}>
                  <CommonButton
                    to="/"
                    scroll
                    type="button"
                    btnText="Return to Home Page"
                  />{" "}
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
