import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
import AboutUs from "./Pages/aboutUsPage/aboutUs";
import Registerationpage from "./Pages/registerpage/registerpage";
import Contactuspage from "./Pages/contactUSPage/contactuspage";
import IndexPage from "./Pages/indexpage/indexpage";
import Loginpage from "./Pages/logInPage/loginpage";
import Bookingformpage from "./Pages/bookingformpage/bookingformpage";
import PaymentCancel from "./Pages/bookingformpage/paymentcancel";
import PaymentSuccess from "./Pages/bookingformpage/paymentsuccess";
import PP from "./Pages/Privacy Policy/PP";
import Resetpasswordpage from "./Pages/resetpasswordpage/resetpasspage";
import Error from "./Pages/404error/error";
import TnC from "./Pages/T&C/TnC";
import Loading from "./UpdatedComponents/loading/Loading";
import MyAccount from "./Pages/MyAccount/MyAccount";
import Resetnewpassword from "./UpdatedComponents/resetnewpassword/resetnewpassword";
import Newpasswordpage from "./Pages/newpasswordpage/newpasswordpage";
import WhoShouldUseUS from "./Pages/whoshoulduseus/WhoShouldUseUS";

//  class component
export default class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<IndexPage />} />
            <Route path="/login" element={<Loginpage />} />
            <Route path="/ourconcept" element={<AboutUs />} />
            <Route path="/BookNow" element={<Bookingformpage />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/privacyPolicy" element={<PP />} />
            <Route path="/resetpassword" element={<Resetpasswordpage />} />
            <Route path="/signup" element={<Registerationpage />} />
            <Route path="/contactUs" element={<Contactuspage />} />
            <Route path="/TnC" element={<TnC />} />
            <Route path="/ChangePassword" element={<Newpasswordpage />} />
            <Route path="/useus" element={<WhoShouldUseUS />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}
