import React, { Component } from "react";
import axios from "axios";
import loginpage from "./loginpage.module.scss";
import MainHeader from "../../UpdatedComponents/exioComponents/mainheader/MainHeader";
import AboutImg from "../../UpdatedComponents/exioComponents/aboutImage/AboutImg";
import Navbar from "../../UpdatedComponents/exioComponents/nav/Navbar";
import Loginform from "../../UpdatedComponents/loginform/loginform";
import Footer from "../../UpdatedComponents/exioComponents/footer/Footer";
import { Navigate } from "react-router-dom";

class Loginpage extends Component {
  state = { isloggedin: false };

  componentDidMount = () => {
    if (localStorage.getItem("token")) {
      this.setState({ isloggedin: true });
    } else {
      localStorage.setItem("token", "");
    }
  };
  render() {
    return (
      <>
        {/* ---------------mainheader---------------- */}
        <header className={loginpage.realHeader}>
          {/* <MainHeader /> */}
          <Navbar />
        </header>
        {/* ------------------image------------------ */}
        {/* <AboutImg pageName="Log In" /> */}
        {/* ---------------LogIn---------------- */}
        <div className={loginpage.loginform}>
          <Loginform />
        </div>
        {/* <Footer /> */}
        {this.state.isloggedin && <Navigate to="/" />}
      </>
    );
  }
}

export default Loginpage;
