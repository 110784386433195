import axios from "axios";

class AuthService {
  login = async (email, password, API_URL) => {
    let response = await axios({
      method: "post",
      url: API_URL,
      headers: {
        "content-type": "application/json;charset=utf-8",
      },
      data: {
        email,
        password,
      },
    });
    return response.data;
  };

  logout = async (API_URL) => {
    let response = await axios({
      method: "GET",
      url: API_URL,
    });
    return response.data;
  };
  register = async (username, email, password, API_URL) => {
    return await axios({
      method: "post",
      url: API_URL,
      headers: {
        "content-type": "application/json;charset=utf-8",
      },
      data: { username, email, password },
    });
  };

  getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
}

export default new AuthService();
