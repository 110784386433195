import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";

import styles from "./bookingformSelect.module.scss";
import Counter from "../exioComponents/counter/Counter";
import Validationbox from "../components/validationbox/validationbox";
import Checkbox from "../components/checkbox/Checkbox";

export default class BookingFormSelect extends Component {
  checkSet = () => {
    let { check } = this.props;
    if (!check) check = {};
    check.style = { display: "none" };
    return null;
  };

  callElement = () => {
    return this.props.options.map((ele, i) => {
      return (
        <BookingFormElement
          check={{ text: this.props.counterCheck[i] }}
          push={this.props.productPush}
          activeValue={this.props.value}
          setActiveValue={this.props.setValue}
          index={i}
          counterVal={this.props.counterVal[i]}
          setCounterVal={this.props.setCounterVal}
          ele={ele}
          key={i}
        />
      );
    });
  };
  render() {
    let { check } = this.props;

    return (
      <Row>
        {this.callElement()}
        <Validationbox
          text={
            check
              ? check.text
                ? check.text
                : this.checkSet()
              : "Order is empty."
          }
          style={check ? (check.style ? check.style : {}) : {}}
        />
      </Row>
    );
  }
}

class BookingFormElement extends Component {
  setActive = (e) => {
    e.preventDefault();

    this.props.setActiveValue(
      !this.props.activeValue[this.props.index] ? this.props.ele.name : "",
      this.props.index
    );
    this.props.setCounterVal(0, this.props.index);
  };

  checkSet = () => {
    let { check } = this.props;
    if (!check) check = {};
    check.style = { display: "none" };
    return null;
  };

  render() {
    let { counterVal, setCounterVal, index, ele, activeValue, check } =
      this.props;
    let { name, price, desc, img } = ele;

    return (
      <Col xl={3} lg={6} md={6} sm={12}>
        <Card className={styles.card}>
          <div onClick={this.setActive}>
            <Card.Title className={styles.title}>
              UP TO THE SIZE OF A {desc}
            </Card.Title>
            <div className={styles.imgDiv}>
              <Card.Img
                alt="svb"
                variant="top"
                src={img}
                className={styles.image}
              />
            </div>
            <div
              className={styles.contentDiv}
              style={{
                backgroundColor: activeValue[index]
                  ? "rgb(192 235 255)"
                  : "#fff",
              }}
            >
              <Card.Body className={styles.body}>
                <h6>SIZE GUIDELINE</h6>
                <p>{name}</p>

                <Card.Text className={styles.cardText}>
                  Selecting this item means that your goods will be no larger
                  than that of a {desc}
                </Card.Text>
              </Card.Body>
            </div>
            <Card.Title className={styles.title}>{`R${price}`}</Card.Title>
          </div>
          <Counter
            show={activeValue[index] ? true : false}
            value={counterVal}
            setValue={setCounterVal}
            index={index}
            style={{ margin: "10px auto", padding: "0" }}
          />
        </Card>
        <Validationbox
          text={
            check
              ? check.text
                ? check.text
                : this.checkSet()
              : "Order is empty."
          }
          style={check ? (check.style ? check.style : {}) : {}}
        />
      </Col>
    );
  }
}
