import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Odometer from "react-odometerjs";
import odometer from "./odometer.module.scss";

class AboutusOdometer extends Component {
  state = { value: 0 };
  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({ value: this.props.value });
    }, 100);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.value !== this.state.value) {
      this.timer = setTimeout(() => {
        this.setState({ value: this.props.value });
      }, 100);
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (
      <div>
        {/* <Col lg="3" className={odometer.Col}> */}
        <div className={odometer.singleCounter}>
          <Row>
            <div className={odometer.icon1}>{this.props.icon}</div>
          </Row>
          {/* <Row> */}
          <Row className={odometer.wrapOdometer}>
              <div className="odometerApp">
                <Odometer value={this.state.value} format="(,ddd),dd" />+
              </div>
          </Row>
          {/* </Row> */}

          <p>{this.props.title}</p>
        </div>
        {/* </Col> */}
      </div>
    );
  }
}

export default AboutusOdometer;
