import React, { Component } from "react";
//bootstrap
import { Row, Col } from "react-bootstrap";

//sass
import ty from "./Ty.module.scss";
//box icons
import "boxicons";
import CommonButton from "../commonButton/CommonButton";

class Cy extends Component {
  render() {
    return (
      <>
        <div className={ty.container}>

          <Row className={ty.thankyouText}>
          	<a href="/" class="row" style={{width: "auto", height: "auto", margin: "auto"}}> <img src="/static/media/LOADPOOL-LOGO.5a934580bcc99d2c6a42.png" alt="logo" style={{width: "300px", height: "auto", margin: "auto"}} /></a>
            <div classname="row" style={{margin: "auto", width: "100%", fontWeight: "500", fontSize: "32px", textAlign: "center", marginTop: "2rem",marginBottom: "2rem", color: "black"}}>Your loadpool delivery payment on PayFast was cancelled.</div>
            <div className={ty.thanks}>Thank You !</div>
          </Row>
          <div className={ty.btns}>
            <div
              className={ty.returnBtn}
              // xl={6}
              // md={6}
              // lg={6}
              // xs={12}
            >
              <CommonButton btnText="Return To Home Page" to="/" />
            </div>
            <div
              className={ty.myAccountBtn}
              // xl={6}
              // md={6}
              // lg={6}
              // xs={12}
            >
              <CommonButton
                to={this.props.isloggedin ? "/myaccount" : "/contactus"}
                btnText={
                  this.props.isloggedin ? "Visit to My Account" : "Contact Us"
                }
              ></CommonButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Cy;
