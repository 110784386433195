import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//component Import
import CommonDD from "../commonDD/dd/CommonDD";
import CommonButton from "../commonButton/CommonButton";

//png Import
import logo from "../../../resources/img/LOADPOOL-LOGO.png";

//grid Import
import { Container, Nav, Navbar } from "react-bootstrap";

import navStyle from "./Navbar.module.scss";

// jQuery
import $ from "jquery";
window.jQuery = window.$ = $;

//scss Import

class Navbarr extends Component {
  state = { addClass: false, isLoggedIn: false };

  logoutHandler = () => {
    this.setState({ isloggedin: false });
    window.location = "/";
    localStorage.clear();
  };

  componentDidMount() {
    if (!this.props.booking) {
      $(window).on("scroll", () => {
        if ($(window).scrollTop() > 150) {
          this.setState({ addClass: true });
        } else {
          this.setState({ addClass: false });
        }
      });
    }

    if (localStorage.getItem("token")) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  componentWillUnmount() {
    $(window).stop("scroll", () => {
      if ($(window).scrollTop() > 150) {
        this.setState({ addClass: true });
      } else {
        this.setState({ addClass: false });
      }
    });
  }
  render() {
    return (
      <>
        {/* <div className={navStyle.navcontainer}> */}
        <div
          className={
            this.state.addClass
              ? `${navStyle.nav} ${navStyle.isSticky}`
              : `${navStyle.nav}`
          }
        >
          <Container className={navStyle.container}>
            <Navbar bg="light" expand="lg" className={navStyle.navcontainer}>
              <Navbar.Brand as={Link} to="/" className={navStyle.brand}>
                <img src={logo} alt="logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                className={navStyle.btn}
                aria-controls="basic-navbar-nav"
              >
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav as="ul" className={`${navStyle.navUl} m-auto`}>
                  <CommonDD name="Home" to="/" />
                  <CommonDD name="Our Concept" to="/ourconcept" />
                  <CommonDD name="Who should use us" to="/useus" />
                  <CommonDD name="Contact Us" to="/contactus" />
                  {this.state.isLoggedIn ? (
                    <CommonDD
                      name="My Account"
                      list={[
                        { title: "Welcome " + localStorage.getItem("name"), to: "#", from: 0, 'isName' : true },
                        { title: "Edit Profile", to: "/myaccount", from: 0 },
                        /*{ title: "Job History", to: "/myaccount", from: 1 },*/
                        {
                          title: "Change Password",
                          to: "/ChangePassword",
                          from: 0,
                        },
                        {
                          title: "Logout",
                          to: "#",
                          onClick: this.logoutHandler,
                          from: 0,
                        },
                      ]}
                    />
                  ) : (
                    <CommonDD
                      name="Account Holder Login"
                      list={[
                        { title: "Login", to: "/login", from: 1 },
                        { title: "Register", to: "/signup", from: 1 },
                      ]}
                    />
                  )}
                </Nav>
                <div className={navStyle.btn2}>
                <CommonButton
                  to="/booknow"
                  btnText="Book Now"
                  type="button"
                  nav={true}
                  scroll
                  borderRadius="22px"
                />
              </div>
              </Navbar.Collapse>

              

              
            </Navbar>
          </Container>
        </div>
        {/* <Row style={{ margin: "0 0" }}>
            <Col className={navStyle.navlogo} lg={{ span: 3, offset: 0 }}>
              <img src={logo} alt="logo" />
            </Col>
            <Col className={navStyle.navOptions}>
              <CommonDD
                name="Home"
                list={["Home One", "Home Two", "Home Three"]}
              />
              <CommonDD
                name="Pages"
                list={[
                  "About Us",
                  {
                    title: "Pricing",
                    subHead: ["Pricing Style One", "Pricing Style Two"],
                  },
                  "Testimonials",
                  {
                    title: "Shop",
                    subHead: [
                      "Products",
                      "Cart",
                      "Checkout",
                      "Product Details",
                    ],
                  },
                ]}
              />
              <CommonDD
                name="Services"
                list={["Services Style One", "Services Style Two"]}
              />
              <CommonDD
                name="Blog"
                list={["Blog Style One", "Blog Style Two"]}
              />
              <CommonDD name="Company" show="none" />
              <CommonDD name="Contact Us" show="none" />
            </Col>
            <Col lg={{ span: 2, offset: 1 }} className={navStyle.navbutton}>
              <CommonButton btnText="Get A Quote" />
            </Col>
          </Row> */}
        {/* </div> */}
      </>
    );
  }
}

export default Navbarr;
