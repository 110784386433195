import React, { Component } from "react";
import Dropdown from "../dropdown/Dropdown";
import Mainboxstyle from "./Mainbox.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import Inputbox from "../inputbox/inputbox";
import CommonButton from "../../exioComponents/commonButton/CommonButton";
import Validationbox from "../validationbox/validationbox";
import { Link } from "react-router-dom";

const google = window.google;

class Mainbox extends Component {
  // state = { inputValue: "" };
  // changeHandler = (e) => {
  //   this.setState({ inputValue: e.target.value });
  // };

  // clearInput = () => {
  //   if (this.props.disabled) {
  //     this.setState({ inputValue: "" });
  //   }
  // };
  componentDidMount() {
    // console.log(this.props.city);
  }

  render() {
    let { check, inputValue, setInputValue, addresses } = this.props;
    let loadpoolDepotAddress = {'DURBAN':'45 Richard Carter Road, Mobeni, Durban.','JOHANNESBURG':'19 Jay Road Fairleads, Benoni, Johannesburg.'};
    let loadpoolDepotAddressLatLng = {'DURBAN':new google.maps.LatLng(-29.930900, 30.972430),'JOHANNESBURG':new google.maps.LatLng(-26.119900, 28.358000)};
    let loadpoolDepotAddressLatLngv1 = {'DURBAN':'-29.930900%2C30.972430','JOHANNESBURG':'-26.119900%2C28.358000'};
    let loadpoolDepotAddressLatv1 = {'DURBAN':'-29.930900','JOHANNESBURG':'-26.119900'};
    let loadpoolDepotAddressLngv1 = {'DURBAN':'30.972430','JOHANNESBURG':'28.358000'};
    if (!check) check = {};
    return (
      <>
        <div className={Mainboxstyle.mainbox}>
          <Row className={Mainboxstyle.headings}>
            <Col>
              <h5 className={Mainboxstyle.mainHeading}>{this.props.title}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6
                style={{ display: this.props.display }}
                className={Mainboxstyle.mainSubHeading}
              >
                {this.props.subHeading
                  ? this.props.subHeading
                  : this.props.display}
                {this.props.isLink && (
                  <Link
                    style={{ color: "#098dd4" }}
                    className={Mainboxstyle.mainSubHeading}
                    to="/contactus"
                  >
                    HERE
                  </Link>
                )}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={this.props.md ? this.props.md : 6}>
              {this.props.optionone}
            </Col>
             <div
              style={{
                display: this.props.showInputBox
                  ? this.props.showInputBox
                  : "none",
              }}
              className={Mainboxstyle.anotherInput}
              >
              {(!this.props.disabled) && (
              <Inputbox
              	controlId={this.props.controlId ? this.props.controlId : "floatingInput1"}
                setAddressData={this.props.setAddressData}
                addressData={this.props.addressData}
                city={this.props.city}
                check={{ text: check.text, style: check.style }}
                suggestions={this.props.addresses}
                label={this.props.inputLabel ? this.props.inputLabel : ""}
                disabled={this.props.disabled ? true : false}
                inputValue={inputValue}
                onChange={(value) => {
                  return setInputValue(value);
                }}
                googleMapLatLng={loadpoolDepotAddressLatLng[this.props.city]}
                googleMapLatLngv1={loadpoolDepotAddressLatLngv1[this.props.city]}
                googleMapLatv1={loadpoolDepotAddressLatv1[this.props.city]}
                googleMapLngv1={loadpoolDepotAddressLngv1[this.props.city]}
                depotAddress={loadpoolDepotAddress[this.props.city]}
              />
            )}


              {(!this.props.disabled) && (this.props.isInsurance) && inputValue.length > 0 && (
              <Inputbox
              	controlId={this.props.controlId ? this.props.controlId : null}
              	check={{ text: check.text, style: check.style }}
                label="Insurance Fee"
                disabled={true}
                inputValue={`R${(Number(inputValue) * 3) / 100}`}
              />
            )}

            </div>
            {(this.props.display || this.props.optiontwo) && (
              <Col>
                {this.props.optiontwo
                  ? this.props.optiontwo
                  : this.props.display}
              </Col>
            )}

              {this.props.disabledw !== undefined && (!this.props.disabledw) && (
              <div
              style={{
                display: this.props.showInputBox
                  ? this.props.showInputBox
                  : "none",
              }}
              className={Mainboxstyle.anotherInput}
              >
              <Inputbox
              	controlId={this.props.controlId ? this.props.controlId : "floatingInput2"}
                check={{ text: check.text, style: check.style }}
                label="Loadpool depot address here"
                disabled={this.props.disabled ? true : false}
                inputValue={loadpoolDepotAddress[this.props.city]}
              />
              </div>
            )}


          </Row>
        </div>
      </>
    );
  }
}

export default Mainbox;
