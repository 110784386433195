import React, { Component } from "react";
import resetnewpassword from "./resetnewpassword.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import Labelbox from "../exioComponents/labelbox/labelbox";
import axios from "axios";
import CommonButton from "../exioComponents/commonButton/CommonButton";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

class Resetnewpassword extends Component {
  // state = { validated: false, name: "", email: "", phoneNo: "", password: "" };
  state = {
    validated: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    rememberCheck: false,
    passwordChanged: false,
  };

  oldPassword = (value) => {
    this.setState({
      oldPassword: value,
    });
  };
  newPassword = (value) => {
    this.setState({
      newPassword: value,
    });
  };
  confirmPassword = (value) => {
    this.setState({
      confirmPassword: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.changePass();
    }
    this.setState({ validated: true });
  };
  notify = (text) =>
    toast.success(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyError = (text) =>
    toast.error(text, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  changePass = async () => {
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.notifyError("Passwords do not match");
      // return;
    } else {
      const response = await axios.put(
        // https://admin.loadpool.co.za
        "https://admin.loadpool.co.za/api/v1/user/changePassword",
        {
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
        },
        {
          headers: {
            "content-type": "application/json;charset=utf-8",
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );

      console.log(response);
      if (response.data.statusCode == 201) {
        this.notify("Password changed Successfully ");

        this.setState({
          validated: false,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else if (response.data.statusCode == 304) {
        this.notifyError("Incorrect Password");
      }
    }
  };

  render() {
    return (
      <>
        <ToastContainer />
        <Container
          style={{
            maxWidth: "650px",
            padding: "20px",
            boxShadow: "0 0 20px 3px rgba(24, 23, 23, 0.05)",
             marginBottom: "100px"
          }}
        >
          <Row>
            <Col className={resetnewpassword.accountTitle}>
              <h2>Change Your Password</h2>
            </Col>
          </Row>
          <Row>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Row className={resetnewpassword.formGroup}>
                <Labelbox
                  formlabel="Old Password"
                  feedback={{ type: "invalid", content: "Invalid" }}
                  id="1"
                  type="password"
                  required
                  value={this.state.oldPassword}
                  setValue={this.oldPassword}
                />
                <Labelbox
                  formlabel="New Password"
                  feedback={{ type: "invalid", content: "Invalid" }}
                  id="2"
                  type="password"
                  required={true}
                  value={this.state.newPassword}
                  setValue={this.newPassword}
                  minLength={4}
                />
                <Labelbox
                  formlabel="Confirm Password"
                  feedback={{ type: "invalid", content: "Invalid" }}
                  id="3"
                  type="password"
                  required={true}
                  value={this.state.confirmPassword}
                  setValue={this.confirmPassword}
                  minLength={4}
                />
              </Row>

              <Row className={resetnewpassword.loginBtn}>
                <Col lg={{ span: 12 }}>
                  <CommonButton type="submit" btnText="Save" />
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </>
    );
  }
}

export default Resetnewpassword;
